import React from "react";

import styles from "./ConnectionError.module.css";
import LoadingAnimation from "components/LoadingAnimation/LoadingAnimation";
import Button from "components/Button/Button";
import clsx from "clsx";
import CopyButton from "pages/Space/components/CopyButton/CopyButton";
import { useSelector } from "react-redux";
import DelayedButton from "pages/Space/components/DelayedButton/DelayedButton";
import { getOrganizationConfig } from "utils/organizationUtils";
import {
  selectOrganizationId,
  selectUserRole,
  UserRole,
} from "redux/userRedux";
import { useEndSession } from "pages/Space/components/ConnectionsContext/GatewayConnectionContext";

type ConnectionErrorProps = {
  errorMessage: string;
  loading: boolean;
  showInviteLink: boolean;
  hasSessionEnded?: boolean;
  isGlobalError?: boolean;
};

const ConnectionError = ({
  errorMessage,
  loading,
  showInviteLink,
  hasSessionEnded,
  isGlobalError,
}: ConnectionErrorProps) => {
  const hidden = !errorMessage;
  const userRole = useSelector(selectUserRole);
  const isTherapist = userRole === UserRole.THERAPIST;
  const organizationId = useSelector(selectOrganizationId);
  const organizationConfig = getOrganizationConfig(organizationId);

  const endSession = useEndSession();

  const buttonTimeoutMS = isTherapist ? 7000 : 15000;
  const showSignInButton =
    isTherapist &&
    (errorMessage.includes("Session timed out") ||
      errorMessage.includes("Signing out"));
  const isTransparent = isGlobalError && !hasSessionEnded && !showSignInButton;
  const shouldShowSurvey =
    hasSessionEnded && !isTherapist && !!organizationConfig?.clientSurveyUrl;

  const signIn = () => {
    if (process.env.REACT_APP_HOME_URL) {
      location.href = `https://${process.env.REACT_APP_HOME_URL}`;
    }
  };

  const reloadPage = () => {
    window.location.reload();
  };

  if (hidden) {
    return null;
  }

  return (
    <div
      className={clsx(styles.container, {
        [styles.transparent]: isTransparent,
      })}
    >
      {loading && <LoadingAnimation />}

      {errorMessage}

      {shouldShowSurvey && (
        <p>Help us make things better by answering a few questions!</p>
      )}

      <div className={styles.buttonGroup}>
        {shouldShowSurvey && (
          <Button
            className={styles.signInButton}
            href={organizationConfig?.clientSurveyUrl}
          >
            Start Survey
          </Button>
        )}

        {showSignInButton ? (
          <Button className={styles.signInButton} onClick={signIn}>
            Sign in again
          </Button>
        ) : (
          isGlobalError && (
            <DelayedButton
              onClick={reloadPage}
              className={clsx(styles.resetRoomButton, styles.largeButton)}
              delay={hasSessionEnded ? 0 : buttonTimeoutMS}
              secondary={shouldShowSurvey}
            >
              {isTherapist
                ? "Reload connection to room"
                : "Go back to waiting room"}
            </DelayedButton>
          )
        )}

        {isGlobalError && isTherapist && !hasSessionEnded && (
          <DelayedButton
            onClick={endSession}
            className={clsx(styles.resetRoomButton, styles.largeButton)}
            delay={buttonTimeoutMS}
            secondary
          >
            End session and reset room
          </DelayedButton>
        )}
      </div>

      {showInviteLink && (
        <div className={styles.inviteSection}>
          Invite your client to join with this link:
          <div className={styles.linkRow}>
            <div className={styles.link}>{window.location.href}</div>
            <CopyButton textToCopy={window.location.href}></CopyButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default ConnectionError;

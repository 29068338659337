import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as VideoOffIcon } from "assets/icons/video_off.svg";
import { VideoButton } from "pages/Space/components/Videos/VideosButtons/VideoButton";
import {
  selectIsPeerVideoOff,
  selectIsVideoOff,
  setIsPeerVideoOff,
  setIsVideoOff,
} from "redux/settingsRedux";
import { useTeleoEvent } from "../../ConnectionsContext/teleoPeerEventUtils";
import { getLocalPeerId } from "../../ConnectionsContext/WebRTCContext";

type DisableClientVideoButtonProps = {
  peerId: string;
  className: string;
  style?: React.CSSProperties;
};

export const DisableClientVideoButton = ({
  peerId,
  className,
  style,
}: DisableClientVideoButtonProps) => {
  const dispatch = useDispatch();
  const isPeerVideoOff = useSelector((state) =>
    selectIsPeerVideoOff(state, peerId)
  );
  const isLocalVideoOff = useSelector(selectIsVideoOff);
  const isLocal = peerId === getLocalPeerId();

  const isVideoOff = isLocal ? isLocalVideoOff : isPeerVideoOff;

  const emitIsPeerVideoOff = useTeleoEvent("peer-video-off");

  const toggleVideoOff = () => {
    if (isLocal) {
      dispatch(setIsVideoOff(!isVideoOff));
    } else {
      dispatch(setIsPeerVideoOff({ peerId, isVideoOff: !isVideoOff }));
    }
    emitIsPeerVideoOff({
      isPeerVideoOff: !isVideoOff,
      targetPeerId: peerId,
    });
  };

  return (
    <VideoButton
      className={className}
      style={style}
      onClick={toggleVideoOff}
      tooltipText={isVideoOff ? "Turn on camera" : "Turn off camera"}
      forceVisible={false}
      icon={VideoOffIcon}
      isIndicator={!!isVideoOff}
    />
  );
};

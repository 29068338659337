import { useDispatch, useSelector } from "react-redux";
import { selectUserRole, UserRole } from "redux/userRedux";
import {
  selectBrowserSandboxUrl,
  selectCurrentPage,
  selectCurrentRoomItemId,
  selectCustomColors,
  selectHasLaunchedActivityFromFullScreenVideo,
  selectIsFullScreenVideo,
  selectShowRoomCustomizationActivityModal,
  selectWhiteboardBackground,
  setBrowserSandboxInitialize,
  setBrowserSandboxUrl,
  setCurrentPage,
  setCurrentRoomItemId,
  setCustomColors,
  setHasLaunchedActivityFromFullScreenVideo,
  setIsFullScreenVideo,
  setShowRoomCustomizationActivityModal,
  setWhiteboardBackground,
} from "redux/spaceNavigationRedux";
import {
  selectAudioProviderOnly,
  selectClientCanControl,
  selectIsMuted,
  selectIsVideoOff,
  selectIsViewportLocked,
  selectIsWidescreenVideoEnabled,
  selectPeerIdWithControl,
  selectVideoConferencing,
  setAudioProviderOnly,
  setClientCanControl,
  setIsPeerMuted,
  setIsPeerVideoOff,
  setIsViewportLocked,
  setIsWidescreenVideoEnabled,
  setPeerIdWithControl,
  setVideoConferencing,
} from "redux/settingsRedux";

import {
  selectAlbumPages,
  selectCurrentAlbumPage,
  selectImgKeysToURL,
  selectViewingSnapshot,
  setAlbumPages,
  setCurrentAlbumPage,
  setImgKeysToURL,
  setViewingSnapshot,
} from "redux/clientAlbumRedux";
import {
  selectCurrentClient,
  setCurrentClient,
} from "redux/clientManagementRedux";
import { store } from "redux/reduxStore";
import {
  selectEditItemId,
  selectIsActivityBankOpen,
  setIsProviderEditing,
} from "redux/editRoomNavigationRedux";
import { useTeleoEvent } from "../components/ConnectionsContext/teleoPeerEventUtils";
import {
  getLocalPeerId,
  useOnParticipantConnect,
} from "../components/ConnectionsContext/WebRTCContext";
import { useIsTherapist } from "./useIsTherapist";

// TODO: check if / how to send media errors to peers
// export const useSyncState = (triggerMediaErrorSync: () => void) => {
export const useSyncState = () => {
  const dispatch = useDispatch();
  const isTherapist = useIsTherapist();
  const userRole = useSelector(selectUserRole);
  const currentClient = useSelector(selectCurrentClient);
  const clientCanControl = useSelector(selectClientCanControl);
  const peerIdWithControl = useSelector(selectPeerIdWithControl);
  const currentPage = useSelector(selectCurrentPage);
  const whiteboardBackground = useSelector(selectWhiteboardBackground);
  const customColors = useSelector(selectCustomColors);
  const browserSandboxUrl = useSelector(selectBrowserSandboxUrl);
  const showRoomCustomizationActivityModal = useSelector(
    selectShowRoomCustomizationActivityModal
  );
  const audioProviderOnly = useSelector(selectAudioProviderOnly);
  const videoConferencing = useSelector(selectVideoConferencing);
  const isFullScreenVideo = useSelector(selectIsFullScreenVideo);
  const isWidescreenVideoEnabled = useSelector(selectIsWidescreenVideoEnabled);
  const hasLaunchedActivityFromFullScreenVideo = useSelector(
    selectHasLaunchedActivityFromFullScreenVideo
  );
  const isMuted = useSelector(selectIsMuted);
  const isVideoOff = useSelector(selectIsVideoOff);
  const roomItemId = useSelector(selectCurrentRoomItemId);

  const currentAlbumPage = useSelector(selectCurrentAlbumPage);
  const albumPages = useSelector(selectAlbumPages);
  const imgKeysToURL = useSelector(selectImgKeysToURL);
  const viewingSnapshot = useSelector(selectViewingSnapshot);

  const isViewportLocked = useSelector(selectIsViewportLocked);

  const isActivityBankOpen = useSelector(selectIsActivityBankOpen);
  const editItemId = useSelector(selectEditItemId);

  const isProviderEditing = isActivityBankOpen || !!editItemId;

  const emitIsPeerMuted = useTeleoEvent("peer-muted");
  const emitPeerVideoOff = useTeleoEvent("peer-video-off");

  const emitSync = useTeleoEvent("sync-state", (payload, from) => {
    console.log("received sync state", payload, from);
    const peerId = from?.identity;
    const state = store.getState();
    const currentIsMuted = selectIsMuted(state);
    const currentIsVideoOff = selectIsVideoOff(state);
    if (userRole === UserRole.CLIENT) {
      dispatch(setCurrentClient(payload.currentClient));
      dispatch(setClientCanControl(payload.clientCanControl));
      dispatch(setPeerIdWithControl(payload.peerIdWithControl));
      dispatch(setAudioProviderOnly(payload.audioProviderOnly));
      dispatch(setIsFullScreenVideo(payload.isFullScreenVideo));
      dispatch(setIsWidescreenVideoEnabled(payload.isWidescreenVideoEnabled));
      dispatch(setVideoConferencing(payload.videoConferencing));
      dispatch(
        setHasLaunchedActivityFromFullScreenVideo(
          payload.hasLaunchedActivityFromFullScreenVideo
        )
      );
      if (peerId) {
        dispatch(setIsPeerMuted({ peerId, isMuted: payload.isMuted ?? false }));
        dispatch(
          setIsPeerVideoOff({ peerId, isVideoOff: payload.isVideoOff ?? false })
        );
      }
      const navigationState = payload.navigationState;
      dispatch(setWhiteboardBackground(navigationState.whiteboardBackground));
      dispatch(setCustomColors(navigationState.customColors));
      dispatch(setBrowserSandboxUrl(navigationState.browserSandboxUrl));
      dispatch(setBrowserSandboxInitialize(false));
      dispatch(setCurrentPage(navigationState.currentPage));
      dispatch(setCurrentRoomItemId(navigationState.currentRoomItemId));
      dispatch(
        setShowRoomCustomizationActivityModal(
          navigationState.showRoomCustomizationActivityModal
        )
      );
      // TODO: check why resourceId was never being sent before
      // dispatch(setCurrentResourceId(navigationState.resourceId));
      dispatch(setCurrentAlbumPage(payload.currentAlbumPage));
      dispatch(setAlbumPages(payload.albumPages));
      dispatch(setImgKeysToURL(payload.imgKeysToURL));
      dispatch(setViewingSnapshot(payload.viewingSnapshot));
      // Resend the client's mute and video off state to the provider when they reconnect
      emitIsPeerMuted({
        isPeerMuted: currentIsMuted ?? false,
        targetPeerId: getLocalPeerId(),
      });
      emitPeerVideoOff({
        isPeerVideoOff: currentIsVideoOff ?? false,
        targetPeerId: getLocalPeerId(),
      });
      // Also resend the client's media errors to the provider
      // triggerMediaErrorSync();
      dispatch(setIsViewportLocked(payload.isViewportLocked));
      dispatch(setIsProviderEditing(payload.isProviderEditing));
    }
  });

  useOnParticipantConnect((participant) => {
    if (isTherapist) {
      console.log("emitting sync state");
      if (!peerIdWithControl) {
        dispatch(setPeerIdWithControl(participant.identity));
      }
      // TODO: Solve this without setTimeout.
      // When we send the event right away, the client might not receive the message
      setTimeout(
        () =>
          emitSync({
            currentClient,
            clientCanControl,
            peerIdWithControl: peerIdWithControl ?? participant.identity,
            audioProviderOnly,
            videoConferencing,
            isFullScreenVideo,
            isWidescreenVideoEnabled,
            hasLaunchedActivityFromFullScreenVideo,
            isMuted,
            isVideoOff,
            navigationState: {
              currentPage,
              currentRoomItemId: roomItemId,
              whiteboardBackground,
              customColors,
              browserSandboxUrl,
              showRoomCustomizationActivityModal,
            },
            currentAlbumPage,
            albumPages,
            imgKeysToURL,
            viewingSnapshot,
            isViewportLocked,
            isProviderEditing,
          }),
        2000
      );
    }
  });
};

import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as CursorIcon } from "assets/icons/cursor.svg";
import { VideoButton } from "pages/Space/components/Videos/VideosButtons/VideoButton";
import {
  selectPeerIdWithControl,
  setPeerIdWithControl,
} from "redux/settingsRedux";
import Tooltip from "components/Tooltip/Tooltip";
import clsx from "clsx";
import { selectIsFullScreenVideo } from "redux/spaceNavigationRedux";

import styles from "./CanControlCursorButton.module.css";
import { getLocalPeerId } from "../../ConnectionsContext/WebRTCContext";
import { useIsTherapist } from "pages/Space/hooks/useIsTherapist";
import { useTeleoEvent } from "../../ConnectionsContext/teleoPeerEventUtils";

type CanControlCursorButtonProps = {
  peerId: string;
  className: string;
};

export const CanControlCursorButton = ({
  peerId,
  className,
}: CanControlCursorButtonProps) => {
  const dispatch = useDispatch();
  const localPeerId = getLocalPeerId();
  const isLocal = peerId === localPeerId;
  const peerIdWithControl = useSelector(selectPeerIdWithControl);
  const isFullScreen = useSelector(selectIsFullScreenVideo);
  const isTherapist = useIsTherapist();

  const emitPeerIdWithControl = useTeleoEvent("peer-can-control-cursor");

  const canControlCursor = peerId === peerIdWithControl;

  if (canControlCursor) {
    return (
      <div className={className}>
        <Tooltip text={"Has control"}>
          <div
            className={clsx(styles.peerCanControlIndicatorButton, {
              [styles.fullScreenButton]: isFullScreen,
            })}
          >
            <CursorIcon className={styles.icon} />
          </div>
        </Tooltip>
      </div>
    );
  }

  if ((!canControlCursor && !isTherapist) || isLocal) {
    return null;
  }

  const handleGrantControl = () => {
    dispatch(setPeerIdWithControl(peerId));
    emitPeerIdWithControl({ targetPeerId: peerId });
  };

  return (
    <VideoButton
      className={className}
      onClick={handleGrantControl}
      tooltipText="Grant control"
      forceVisible={false}
      icon={CursorIcon}
    />
  );
};

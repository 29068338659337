import React, { ChangeEvent, useRef, useState } from "react";

import styles from "./ChangePosterSideBar.module.css";
import { useDispatch, useSelector } from "react-redux";
import { selectMeetingID } from "redux/spaceNavigationRedux";
import { selectEditItemId, setEditItemId } from "redux/editRoomNavigationRedux";
import Button from "components/Button/Button";
import { deleteFile, uploadFile } from "utils/fileUtils";
import { selectCurrentRoomId, selectUserId } from "redux/userRedux";
import {
  GetPosterFileInfoDocument,
  useGetPosterFileInfoQuery,
  useInsertCustomPosterRoomItemMutation,
} from "generated/graphql";
import { evictCurrentRoomItems } from "utils/dbUtils";
import { v4 as uuid } from "uuid";
import { ReactComponent as XIcon } from "assets/icons/x.svg";
import { logUsageEvent } from "utils/metricsUtils";
import { logUnexpectedError } from "utils/errorUtils";
import { MAX_FILE_SIZE } from "pages/Space/components/AddResourceModal/AddResourceModal";
import { useRoomEditingChangePosterAnalytics } from "../../hooks/useRoomEditingAnalytics";
import { useTeleoEvent } from "pages/Space/components/ConnectionsContext/teleoPeerEventUtils";

const GENERIC_ERROR_MESSAGE =
  "Sorry, there was a problem uploading your image. Please try again later.";

type ChangePosterSideBarProps = {};

const ChangePosterSideBar = ({}: ChangePosterSideBarProps) => {
  const userId = useSelector(selectUserId);
  const roomId = useSelector(selectCurrentRoomId);
  const meetingID = useSelector(selectMeetingID);
  const editItemId = useSelector(selectEditItemId);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [fileInputValue, setFileInputValue] = useState<string>("");
  const [uploading, setIsUploading] = useState(false);
  const dispatch = useDispatch();
  const [insertCustomPosterRoomItemMutation] =
    useInsertCustomPosterRoomItemMutation();

  const { trackChangePoster } = useRoomEditingChangePosterAnalytics();

  const { data } = useGetPosterFileInfoQuery({
    variables: {
      itemId: editItemId,
    },
    skip: !roomId,
  });

  const close = () => {
    dispatch(setEditItemId(undefined));
  };

  const hasCustomImage =
    data?.room_item_by_pk && !!data?.room_item_by_pk.icon_file;
  const currentImageName = hasCustomImage
    ? data?.room_item_by_pk?.icon_file?.name
    : "Default";

  const chooseFile = () => {
    fileInputRef.current?.click();
  };

  const alertAndResetUpload = (message: string) => {
    alert(message);
    setIsUploading(false);
    setFileInputValue("");
  };

  const sendRemoteRoomItemChanged = useTeleoEvent("room-items-changed");

  const changeHandler = async (event: ChangeEvent<HTMLInputElement>) => {
    try {
      if (!event.target.files || !roomId || !userId) {
        return;
      }

      const file = event.target.files[0];
      if (!file) {
        return;
      }
      setIsUploading(true);
      // Check file size
      if (file.size > MAX_FILE_SIZE) {
        alertAndResetUpload(
          "Files must be 100 MB or smaller. Please choose a different file."
        );
        return;
      }
      // Upload file
      const uploadResult = await uploadFile(file, "activity");
      if (!uploadResult || !uploadResult.key) {
        alertAndResetUpload(GENERIC_ERROR_MESSAGE);
        return;
      }
      const key = uploadResult.key;
      const previousKey = data?.room_item_by_pk?.icon_file?.key;

      // Save image to the DB
      const { errors } = await insertCustomPosterRoomItemMutation({
        variables: {
          itemId: editItemId,
          iconFileId: uuid(),
          iconFileKey: key,
          iconFileName: file.name,
          userId,
        },
        update: evictCurrentRoomItems(roomId),
        refetchQueries: [
          { query: GetPosterFileInfoDocument, variables: { roomId } },
        ],
      });
      sendRemoteRoomItemChanged({});
      if (errors) {
        alertAndResetUpload(GENERIC_ERROR_MESSAGE);
        return;
      }
      trackChangePoster();
      logUsageEvent("CHANGED_POSTER", meetingID);

      // Delete old image file in s3
      if (previousKey) {
        deleteFile(previousKey).catch(logUnexpectedError);
      }

      setIsUploading(false);
    } catch (err) {
      logUnexpectedError(err);
      alertAndResetUpload(GENERIC_ERROR_MESSAGE);
      setIsUploading(false);
    }
  };

  return (
    <div className={styles.container} data-testid="change-poster-side-bar">
      <div className={styles.headerRow}>
        <div className={styles.header}>Edit Poster</div>
        <XIcon className={styles.xIcon} onClick={close} />
      </div>
      <div className={styles.body}>
        <div className={styles.message}>
          Customize the poster with your own image.
        </div>
        <div className={styles.horizontalLine} />
        <div className={styles.sectionHeader}>Current Image</div>
        <div data-testid="poster-image-name" className={styles.message}>
          {currentImageName || ""}
        </div>
        <div className={styles.horizontalLine} />
        <div className={styles.sectionHeader}>Choose New Image</div>
        <input
          data-testid="change-poster-upload-input"
          ref={fileInputRef}
          type="file"
          name="file"
          style={{ display: "none" }}
          accept=".jpg,.jpeg,.png,.pdf"
          value={fileInputValue}
          onChange={changeHandler}
        />
        <Button onClick={chooseFile} disabled={uploading}>
          {uploading ? "..." : "Upload"}
        </Button>
      </div>
    </div>
  );
};

export default ChangePosterSideBar;

import React, { useEffect } from "react";
import styles from "./Album.module.css";
import ActivityNavigationHeader from "pages/Space/components/ActivityNavigationHeader/ActivityNavigationHeader";
import { useGetAlbumSnapshotsQuery } from "generated/graphql";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentClient } from "redux/clientManagementRedux";
import { getFileUrls } from "utils/fileUtils";
import { UserRole, selectUserRole } from "redux/userRedux";
import { ReactComponent as XIcon } from "assets/icons/x.svg";
import nextIcon from "assets/album/next.png";
import { selectCanControl } from "redux/settingsRedux";
import clsx from "clsx";
import albumTitle from "assets/album/album_title.png";
import { useResizeAlbum } from "./useResizeAlbum";
import { useResizeSnapshot } from "./useResizeSnapshot";
import { useRemoteAlbum } from "./useRemoteAlbum";
import { logUnexpectedError } from "utils/errorUtils";
import {
  Page,
  Snapshot,
  selectImgKeysToURL,
  selectAlbumPages,
  selectViewingSnapshot,
  setImgKeysToURL,
  setAlbumPages,
  setViewingSnapshot,
  selectCurrentAlbumPage,
  setCurrentAlbumPage,
} from "redux/clientAlbumRedux";

interface AlbumProps {}

const Album = ({}: AlbumProps) => {
  const dispatch = useDispatch();
  const userRole = useSelector(selectUserRole);
  const clientCanControl = useSelector(selectCanControl);
  const userCanControl =
    (userRole == UserRole.CLIENT && clientCanControl) ||
    userRole === UserRole.THERAPIST;
  const albumContainerRef = React.useRef<HTMLDivElement>(null);
  const snapshotImageRef = React.useRef<HTMLImageElement>(null);

  const currentClient = useSelector(selectCurrentClient);
  const currentAlbumPage = useSelector(selectCurrentAlbumPage);
  const albumPages = useSelector(selectAlbumPages);
  const imgKeysToURL = useSelector(selectImgKeysToURL);
  const viewingSnapshot = useSelector(selectViewingSnapshot);

  const { data: snapshots } = useGetAlbumSnapshotsQuery({
    variables: { clientId: currentClient?.canonical_id },
    skip: !currentClient || userRole === UserRole.CLIENT,
  });

  const { sendInitializeAlbum, sendMoveToPage, sendSetViewingSnapshot } =
    useRemoteAlbum();

  useEffect(() => {
    if (userRole === UserRole.THERAPIST) {
      if (currentClient && snapshots) {
        const newPages: Page[] = [];
        const imgKeys: string[] = [];
        for (const snapshot of snapshots.resource_snapshot) {
          let lastPage =
            newPages.length > 0 ? newPages[newPages.length - 1] : undefined;
          const snapshotDate = new Date(snapshot.created_at);
          const day = snapshotDate.toLocaleString("en-US", {
            year: "numeric",
            month: "short",
            day: "2-digit",
          });

          if (
            !lastPage ||
            lastPage.snapshots.length === 4 ||
            lastPage.date !== day
          ) {
            const differentDay = lastPage?.date !== day;
            lastPage = {
              title: differentDay ? day : `${day}, continued`,
              snapshots: [],
              date: day,
            };
            newPages.push(lastPage);
          }

          lastPage.snapshots.push({
            fileKey: snapshot.file_key,
            thumbnailKey: snapshot.thumbnail_file_key,
          });
          imgKeys.push(snapshot.file_key, snapshot.thumbnail_file_key);
        }
        const getURLsFromKeys = async () => {
          const newImgKeysToURL = await getFileUrls(imgKeys, "snapshot");
          dispatch(setImgKeysToURL(newImgKeysToURL));
          dispatch(setAlbumPages(newPages));
          dispatch(setCurrentAlbumPage(0));
          dispatch(setViewingSnapshot(undefined));

          sendInitializeAlbum({
            pages: newPages,
            imgKeysToURL: newImgKeysToURL,
            currentPage: 0,
            viewingSnapshot: undefined,
          });
        };
        getURLsFromKeys().catch(logUnexpectedError);
      } else {
        dispatch(setImgKeysToURL({}));
        dispatch(setAlbumPages(undefined));
        dispatch(setCurrentAlbumPage(0));
        dispatch(setViewingSnapshot(undefined));

        sendInitializeAlbum({
          pages: undefined,
          imgKeysToURL: {},
          currentPage: 0,
          viewingSnapshot: undefined,
        });
      }
    }
  }, [snapshots, currentClient]);

  const albumSize = useResizeAlbum({ albumContainerRef });
  const snapshotWidth = useResizeSnapshot({
    albumContainerRef,
    snapshotImageRef,
    viewingSnapshot,
  });

  const messageStyle = {
    fontSize: (albumSize?.width || 0) / 40,
  };
  const pageTitleStyle = {
    fontSize: (albumSize?.width || 0) / 50,
  };
  const snapshotThumbnailImageStyle = {
    borderWidth: (albumSize?.width || 0) / 800,
  };

  const handleSnapshotClick = (snapshot: Snapshot) => {
    if (userCanControl) {
      dispatch(setViewingSnapshot(snapshot));
      sendSetViewingSnapshot({ snapshot });
    }
  };

  const handleChangePageClick = (page: number) => {
    if (userCanControl) {
      dispatch(setCurrentAlbumPage(page));
      sendMoveToPage({ page });
    }
  };

  const pagesComponents = albumPages
    ? albumPages.map((page, i) => {
        const isRightPage = i % 2 === 0;
        const showNavigationButton =
          isRightPage === false || (isRightPage === true && albumPages[i + 1]);
        return (
          <div
            className={isRightPage ? styles.rightPage : styles.leftPage}
            key={i}
          >
            <div className={styles.pageTitle} style={pageTitleStyle}>
              {page.title}
            </div>
            <div className={styles.snapshotList}>
              {page.snapshots.map((snapshot) => (
                <div
                  className={styles.snapshotThumbnail}
                  key={snapshot.fileKey}
                >
                  <img
                    src={imgKeysToURL[snapshot.thumbnailKey]}
                    style={snapshotThumbnailImageStyle}
                    onClick={() => handleSnapshotClick(snapshot)}
                  />
                </div>
              ))}
            </div>
            {showNavigationButton && (
              <div className={styles.changePageButton}>
                <img
                  src={nextIcon}
                  onClick={() =>
                    handleChangePageClick(
                      isRightPage ? currentAlbumPage + 2 : currentAlbumPage - 2
                    )
                  }
                />
              </div>
            )}
          </div>
        );
      })
    : [];

  pagesComponents.unshift(
    <div className={styles.albumTitle} key={"TITLE"}>
      <img className={styles.albumTitleImage} src={albumTitle} />
    </div>
  );

  if (!currentClient && userRole === UserRole.THERAPIST) {
    pagesComponents.push(
      <div className={styles.noSnapshotsPage} key="NO_CLIENT">
        <div className={styles.noSnapshots} style={messageStyle}>
          Please select a client to show your shared snapshots.
        </div>
      </div>
    );
  } else if (pagesComponents?.length === 1) {
    pagesComponents.push(
      <div className={styles.noSnapshotsPage} key="NO_SNAPSHOTS">
        <div className={styles.noSnapshots} style={messageStyle}>
          Your therapist hasn’t saved any snapshots here yet.
        </div>
      </div>
    );
  }

  return (
    <div
      className={clsx(styles.container, {
        [styles.disableControl]: !userCanControl,
      })}
    >
      <div className={styles.header}>
        <ActivityNavigationHeader isExternal={false} />
      </div>
      <div className={styles.albumContainer} ref={albumContainerRef}>
        <div className={styles.album} style={albumSize}>
          {pagesComponents && pagesComponents[currentAlbumPage]}
          {pagesComponents && pagesComponents[currentAlbumPage + 1]}
        </div>
        {viewingSnapshot && (
          <div
            className={clsx(styles.snapshotDetails, {
              [styles.zooming]: !!snapshotWidth,
            })}
          >
            <div className={styles.closeButton}>
              <XIcon
                className={styles.XIcon}
                onClick={() => {
                  if (userCanControl) {
                    dispatch(setViewingSnapshot(undefined));
                    sendSetViewingSnapshot({ snapshot: undefined });
                  }
                }}
              />
            </div>

            <img
              src={imgKeysToURL[viewingSnapshot.fileKey]}
              className={styles.snapshot}
              ref={snapshotImageRef}
              style={
                !!snapshotWidth
                  ? { width: snapshotWidth, height: "unset" }
                  : undefined
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Album;

import React from "react";
import clsx from "clsx";
import styles from "./WaitingRoomMenu.module.css";
import { useSelector } from "react-redux";
import {
  selectIsFullScreenVideo,
  selectMeetingID,
} from "redux/spaceNavigationRedux";
import { useGetWaitingRoomParticipantsQuery } from "generated/graphql";
import { WaitingRoomMenuRow } from "./WaitingRoomMenuRow";

type WaitingRoomMenuProps = {
  closeMenu: () => void;
};

export const WaitingRoomMenu = ({ closeMenu }: WaitingRoomMenuProps) => {
  const isFullScreenVideo = useSelector(selectIsFullScreenVideo);
  const meetingID = useSelector(selectMeetingID);

  const { data } = useGetWaitingRoomParticipantsQuery({
    variables: {
      meetingID: meetingID ?? "",
    },
    skip: !meetingID,
  });

  const participants = data?.waiting_room_participant;
  return (
    <div
      className={clsx(styles.menuContainer, {
        [styles.menuContainerElevated]: isFullScreenVideo,
      })}
    >
      {participants?.map(
        ({ client_id: clientId, client_name: name }, index) => {
          return (
            <WaitingRoomMenuRow
              key={index}
              clientId={clientId}
              name={name}
              closeMenu={closeMenu}
            />
          );
        }
      )}
      {data && participants?.length === 0 ? (
        <div>No one is currently waiting.</div>
      ) : null}
    </div>
  );
};

import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { selectIsMuted, setIsMuted } from "redux/settingsRedux";
import { ReactComponent as MicOffIcon } from "assets/icons/mic_off.svg";
import { VideoButton } from "pages/Space/components/Videos/VideosButtons/VideoButton";
import { useTeleoEvent } from "../../ConnectionsContext/teleoPeerEventUtils";
import { getLocalPeerId } from "../../ConnectionsContext/WebRTCContext";

type MuteButtonProps = {
  className: string;
  style?: React.CSSProperties;
};

export const MuteButton = ({ className, style }: MuteButtonProps) => {
  const isMuted = useSelector(selectIsMuted);

  const dispatch = useDispatch();
  const emitIsPeerMuted = useTeleoEvent("peer-muted");

  const toggleMute = () => {
    dispatch(setIsMuted(!isMuted));
    emitIsPeerMuted({ isPeerMuted: !isMuted, targetPeerId: getLocalPeerId() });
  };

  return (
    <VideoButton
      className={className}
      style={style}
      onClick={toggleMute}
      tooltipText={isMuted ? "Unmute" : "Mute"}
      forceVisible={!!isMuted}
      icon={MicOffIcon}
      isIndicator={!!isMuted}
    />
  );
};

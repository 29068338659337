import React from "react";

import styles from "./SideBar.module.css";
import { useSelector } from "react-redux";
import { selectRoomItems } from "redux/spaceNavigationRedux";
import {
  selectEditItemId,
  selectIsActivityBankOpen,
} from "redux/editRoomNavigationRedux";
import ChangePosterSideBar from "../ChangePosterSideBar/ChangePosterSideBar";
import { selectClientFileOpen } from "redux/clientManagementRedux";
import ClientFile from "pages/Space/components/ClientFile/ClientFile";
import ActivityBank from "../ActivityBank/ActivityBank";

type SideBarProps = {};

const SideBar = ({}: SideBarProps) => {
  const roomItems = useSelector(selectRoomItems);
  const isActivityBankOpen = useSelector(selectIsActivityBankOpen);
  const editItemId = useSelector(selectEditItemId);
  const isClientFileOpen = useSelector(selectClientFileOpen);

  const isEditItemPoster =
    !!editItemId &&
    roomItems?.find((item) => item.id === editItemId)?.icon_id === "POSTER";

  const getContent = () => {
    if (isClientFileOpen) {
      return <ClientFile />;
    }
    if (isEditItemPoster) {
      return <ChangePosterSideBar />;
    }
    if (isActivityBankOpen) {
      return <ActivityBank />;
    }
  };

  return <div className={styles.container}>{getContent()}</div>;
};

export default SideBar;

import { fabric, isFabricIText } from "utils/fabricUtils";
import { fabricTypes } from "utils/fabric-impl";
import { useRef } from "react";
import { DEFAULT_OPTION } from "./WhiteboardControls";
import { ControlButtonType } from "./ControlButton";
import { HIT_SLOP } from "./Whiteboard";
import { getRemoteEraserBrush } from "../SpaceRoom/utils/drawingUtils";
import { useSelector } from "react-redux";
import { selectUserRole, UserRole } from "redux/userRedux";
import { useTeleoEvent } from "pages/Space/components/ConnectionsContext/teleoPeerEventUtils";

export const useRemoteCanvas = (
  canvas: fabricTypes.Canvas | undefined,
  remoteTmpCanvas: fabricTypes.Canvas | undefined,
  canvasRef: React.MutableRefObject<fabricTypes.Canvas | undefined>,
  remoteTmpCanvasRef:
    | React.MutableRefObject<fabricTypes.Canvas | undefined>
    | undefined,
  visibleHeightRef: React.MutableRefObject<number>,
  maybeSaveWhiteboardState: () => void
) => {
  const userRole = useSelector(selectUserRole);
  const remoteBrushRef = useRef<fabricTypes.BaseBrush>();
  const defaultColor =
    DEFAULT_OPTION.config.type === ControlButtonType.FREE_DRAW
      ? DEFAULT_OPTION.config.color
      : "";
  const remoteBrushColor = useRef(defaultColor);

  // TODO: consider if we still need this queue of events if the canvas is initialized after we receive events.
  // const queuedEvents = useRef<MessageEvent[]>([]);

  // useEffect(() => {
  //   if (canvas && remoteTmpCanvas) {
  //     while (queuedEvents.current.length > 0) {
  //       const event = queuedEvents.current.shift();
  //       if (event) {
  //         onReceiveMessageCallback(event);
  //       }
  //     }
  //   }
  // }, [canvas, remoteTmpCanvas]);

  const addObject = (serializedObject: any, id: string) => {
    let object;
    // check the type of the obj we received and create an object of that type
    if (serializedObject.type === "rect") {
      object = new fabric.Rect({
        height: serializedObject.height,
        width: serializedObject.width,
      });
    } else if (serializedObject.type === "circle") {
      object = new fabric.Circle({
        radius: serializedObject.radius,
      });
    } else {
      object = new fabric.Triangle({
        width: serializedObject.width,
        height: serializedObject.height,
      });
    }
    // set the new object's id to the id we received
    object.set({ id: id });
    // add the object to the canvas
    canvasRef.current?.add(object);
    canvasRef.current?.renderAll();
  };

  const addPath = (serializedObject: any, id: string) => {
    fabric.util.enlivenObjects(
      [serializedObject],
      function (objects: any) {
        objects.forEach(function (o: any) {
          o.set({ id, perPixelTargetFind: true, padding: HIT_SLOP * 2 });
          canvasRef.current?.add(o);
        });
      },
      ""
    );
    canvasRef.current?.renderAll();
  };

  const modifyObjects = (objects: { obj: any; id: string }[]) => {
    // check the objects on our canvas for one with a matching id
    canvasRef.current?.getObjects().forEach((object) => {
      const objectId = object.id;
      const matchingObject = objects.find((o) => o.id === objectId);
      if (matchingObject) {
        fabric.util.enlivenObjects(
          [matchingObject.obj],
          function (objects: any) {
            const enlivenedObject = objects[0];
            // set the object on the canvas to the object we received from the socket server
            object.set(enlivenedObject);
            // calling setCoords ensures that the canvas recognizes the object in its new position
            object.setCoords();
            canvasRef.current?.renderAll();
          }
        );
      }
    });
  };

  const reset = () => {
    const objects = canvasRef.current?.getObjects();
    if (objects) {
      canvasRef.current?.remove(...objects);
    }
    if (userRole === UserRole.THERAPIST) {
      maybeSaveWhiteboardState();
    }
  };

  const addOrModifyText = (data: {
    id: string;
    text: string;
    options: fabricTypes.ITextOptions;
  }) => {
    if (!canvasRef.current) return;
    let found = false;
    // check the objects on our canvas for one with a matching id
    canvasRef.current?.getObjects().forEach((object) => {
      if (object.id === data.id && isFabricIText(object)) {
        found = true;
        // ensures 2 users can not edit text at the same time
        if (object.isEditing) {
          object.exitEditing();
        }
        // set the object on the canvas to the object we received from the socket server
        object.set(data.options);
        object.set({ text: data.text });
        // calling setCoords ensures that the canvas recognizes the object in its new position
        object.setCoords();
        canvasRef.current?.renderAll();
      }
    });

    // If we didn't find a matching object, add a new one
    if (!found) {
      const text = new fabric.IText(
        data.text,
        data.options
      ) as fabricTypes.IText;
      text.set({ id: data.id });
      canvasRef.current.add(text);
      text.on("changed", () => {
        emitAddOrModifyText({
          id: text.id,
          text: text.text || "",
          options: data.options,
        });
      });
      canvasRef.current.renderAll();
    }
  };

  const REMOTE_PAINT_BRUSH_HEIGHT_MULTIPLIER = 0.006;

  const startPath = (serializedPoint: any) => {
    if (!remoteTmpCanvasRef?.current) return;

    const brush = new fabric.PencilBrush(remoteTmpCanvasRef.current);
    brush.width =
      visibleHeightRef.current * REMOTE_PAINT_BRUSH_HEIGHT_MULTIPLIER;
    brush.color = remoteBrushColor.current;
    // @ts-ignore
    brush.decimate = 2; // make the lines a bit smoother
    const point = new fabric.Point(serializedPoint.x, serializedPoint.y);
    // @ts-ignore
    brush.onMouseDown(point, { pointer: point, e: {} });
    remoteBrushRef.current = brush;
  };

  const startEraserPath = (serializedPoint: any) => {
    if (!remoteTmpCanvasRef?.current) return;

    const brush = getRemoteEraserBrush(
      visibleHeightRef.current,
      remoteTmpCanvasRef.current
    );
    const point = new fabric.Point(serializedPoint.x, serializedPoint.y);
    // @ts-ignore
    brush.onMouseDown(point, { pointer: point, e: {} });
    remoteBrushRef.current = brush;
  };

  const drawPath = (serializedPoint: any) => {
    if (!remoteTmpCanvasRef?.current) return;

    const point = new fabric.Point(serializedPoint.x, serializedPoint.y);
    // @ts-ignore
    remoteBrushRef.current?.onMouseMove(point, { pointer: point, e: {} });
  };

  const endPath = (serializedPoint: any) => {
    if (!remoteTmpCanvasRef?.current) return;

    const point = new fabric.Point(serializedPoint.x, serializedPoint.y);
    // @ts-ignore
    remoteBrushRef.current?.onMouseUp({ pointer: point, e: {} });
    remoteBrushRef.current = undefined;
  };

  const changeBrushColor = (color: string) => {
    remoteBrushColor.current = color;
  };

  const emitAddOrModifyText = useTeleoEvent(
    "text-added-or-modified",
    (payload) => {
      addOrModifyText(payload);
    }
  );
  const emitAddObject = useTeleoEvent("object-added", (payload) => {
    addObject(payload.obj, payload.id);
  });
  const emitAddPath = useTeleoEvent("path-added", (payload) => {
    console.log("path added", payload);
    addPath(payload.obj, payload.id);
  });
  const emitModifyObjects = useTeleoEvent("objects-modified", (payload) => {
    modifyObjects(payload);
  });
  const emitReset = useTeleoEvent("reset", () => {
    reset();
  });
  const emitEraserPathMouseDown = useTeleoEvent(
    "eraser-path-mouse-down",
    (payload) => {
      startEraserPath(payload.point);
    }
  );
  const emitPathMouseDown = useTeleoEvent("path-mouse-down", (payload) => {
    startPath(payload.point);
  });
  const emitPathMouseMove = useTeleoEvent("path-mouse-move", (payload) => {
    drawPath(payload.point);
  });
  const emitPathMouseUp = useTeleoEvent("path-mouse-up", (payload) => {
    endPath(payload.point);
  });
  const emitBrushChange = useTeleoEvent("brush-change", (payload) => {
    changeBrushColor(payload.color);
  });

  // const onReceiveMessageCallback = async (event: MessageEvent) => {
  //   const data = await readLargeEvent(event);
  //   if (!data) {
  //     return;
  //   }
  //   const eventType = data.event;
  //   if (!canvasRef?.current || !remoteTmpCanvasRef?.current) {
  //     queuedEvents.current.push(event);
  //     return;
  //   }
  //   if (eventType === "object-added") {
  //     addObject(data.data.obj, data.data.id);
  //   } else if (eventType === "objects-modified") {
  //     modifyObjects(data.data);
  //   } else if (eventType === "reset") {
  //     reset();
  //   } else if (eventType === "path-mouse-down") {
  //     startPath(data.data.point);
  //   } else if (eventType === "eraser-path-mouse-down") {
  //     startEraserPath(data.data.point);
  //   } else if (eventType === "path-mouse-move") {
  //     drawPath(data.data.point);
  //   } else if (eventType === "path-mouse-up") {
  //     endPath(data.data.point);
  //   } else if (eventType === "brush-change") {
  //     changeBrushColor(data.data.color);
  //   } else if (eventType === "text-added-or-modified") {
  //     addOrModifyText(data.data);
  //   } else if (eventType === "path-added") {
  //     addPath(data.data.obj, data.data.id);
  //   }
  // };

  return {
    emitAddObject,
    emitAddPath,
    emitModifyObjects,
    emitReset,
    emitEraserPathMouseDown,
    emitPathMouseDown,
    emitPathMouseMove,
    emitPathMouseUp,
    emitBrushChange,
    emitAddOrModifyText,
  };
};

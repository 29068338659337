import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
  useRef,
  useCallback,
} from "react";
import {
  LiveKitRoom,
  useConnectionState,
  useDataChannel,
  useLocalParticipant,
  useParticipantTracks,
  useRemoteParticipants,
  useRoomContext,
  setLogLevel,
} from "@livekit/components-react";
import type { ReceivedDataMessage } from "@livekit/components-core";
import { useGatewayConnectionCtx } from "./GatewayConnectionContext";
import { Participant, RemoteParticipant, Track } from "livekit-client";
import mixpanel from "mixpanel-browser";
import { v4 as uuidv4 } from "uuid";
import { logUnexpectedError } from "utils/errorUtils";
import EventEmitter from "events";
import { INITIAL_VIDEO_PRESET, useLocalUserMedia } from "./useLocalUserMedia";

export type TeleoPeer = Participant;

type WebRTCContextProviderProps = {
  children: ReactNode;
};
const serverUrl = "wss://teleo-2rkw03ph.livekit.cloud";
export const ROOM_LIVEKIT_PARTICIPANT_ID = "room-gst-producer";
const MAX_MESSAGE_SIZE = 15 * 1024;

setLogLevel("info");

export const getLocalPeerId = () => {
  return mixpanel.get_distinct_id() as string;
};

type WebRTCContextType = {
  sendMessageInChunks: (
    eventName: string,
    message: Uint8Array,
    reliable?: boolean
  ) => void;
  eventEmitter: EventEmitter;
};

const WebRTCContext = createContext<WebRTCContextType | undefined>(undefined);

export const useWebRTCContext = () => {
  const context = useContext(WebRTCContext);
  if (!context) {
    throw new Error(
      "useWebRTCContext must be used within a WebRTCContextProvider"
    );
  }
  return context;
};

export const WebRTCContextProvider = ({
  children,
}: WebRTCContextProviderProps) => {
  const eventEmitterRef = useRef(new EventEmitter());
  const { sendMessageInChunks } =
    useLiveKitDataChannelMessageHandler(eventEmitterRef);

  useLocalUserMedia();

  const contextValue = useMemo(
    () => ({ sendMessageInChunks, eventEmitter: eventEmitterRef.current }),
    [sendMessageInChunks]
  );
  return (
    <WebRTCContext.Provider value={contextValue}>
      {children}
    </WebRTCContext.Provider>
  );
};

export const WebRTCContextProviderWrapper = ({
  children,
}: WebRTCContextProviderProps) => {
  const { livekitToken } = useGatewayConnectionCtx();
  const [roomState, setRoomState] = useState<"connected" | "disconnected">(
    "disconnected"
  );

  useEffect(() => {
    if (livekitToken) {
      setRoomState("disconnected");
    }
  }, [livekitToken]);

  return (
    <LiveKitRoom
      key={livekitToken}
      serverUrl={serverUrl}
      token={livekitToken}
      style={{ height: "100%", margin: 0 }}
      onConnected={() => setRoomState("connected")}
      options={{
        videoCaptureDefaults: { resolution: INITIAL_VIDEO_PRESET.resolution },
        adaptiveStream: true,
        dynacast: true,
        publishDefaults: {
          backupCodec: true,
          videoCodec: "vp9",
        },
      }}
    >
      <WebRTCContextProvider>
        {roomState === "connected" && children}
      </WebRTCContextProvider>
    </LiveKitRoom>
  );
};

const useLiveKitDataChannelMessageHandler = (
  emitterRef: React.MutableRefObject<EventEmitter>
) => {
  const dataChannelMessagesPartsRef = useRef<{ [key: string]: Uint8Array[] }>(
    {}
  );

  const dataChannelListener = useCallback((message: ReceivedDataMessage) => {
    const { from, payload, topic } = message;
    console.log("received datachannel message", message);

    if (!topic) {
      logUnexpectedError(
        "Received datachannel message without topic. ignoring"
      );
      return;
    }

    const eventName = topic.split("|")[0];
    const messageId = topic.split("|")[1];
    const chunkIndex = parseInt(topic.split("|")[2], 10);
    const totalChunks = parseInt(topic.split("|")[3], 10);

    const messageParts = dataChannelMessagesPartsRef.current[messageId] || [];
    messageParts[chunkIndex] = payload;
    dataChannelMessagesPartsRef.current[messageId] = messageParts;

    // Handle the complete message
    if (
      messageParts.length === totalChunks &&
      messageParts.every((part) => part)
    ) {
      const completeMessage = new Uint8Array(
        messageParts.reduce((acc, part) => acc + part.length, 0)
      );
      let offset = 0;
      messageParts.forEach((part) => {
        completeMessage.set(part, offset);
        offset += part.length;
      });

      const messageString = new TextDecoder().decode(completeMessage);
      const messageObject = JSON.parse(messageString);

      console.log(
        `emitting received event. had ${messageParts.length} parts`,
        eventName,
        messageObject
      );
      emitterRef.current.emit(eventName, { from, payload: messageObject });

      // Clear the stored parts
      delete dataChannelMessagesPartsRef.current[messageId];
    }
  }, []);

  const { send } = useDataChannel(dataChannelListener);

  const sendMessageInChunks = useCallback<
    WebRTCContextType["sendMessageInChunks"]
  >(
    (eventName, message, reliable) => {
      const totalChunks = Math.ceil(message.length / MAX_MESSAGE_SIZE);
      const messageId = uuidv4();
      for (let i = 0; i < message.length; i += MAX_MESSAGE_SIZE) {
        const chunk = message.slice(i, i + MAX_MESSAGE_SIZE);
        const currentIndex = i / MAX_MESSAGE_SIZE;
        const chunkTopic = [
          eventName,
          messageId,
          currentIndex,
          totalChunks,
        ].join("|");

        send(chunk, { topic: chunkTopic, reliable });
      }
    },
    [send]
  );

  return { sendMessageInChunks };
};

export const useWebRTCEvent = (
  eventName: string,
  listener?: (...args: any[]) => void
) => {
  const { sendMessageInChunks, eventEmitter } = useWebRTCContext();

  useEffect(() => {
    if (!listener) {
      return;
    }
    const handleEvent = ({
      payload,
      from,
    }: {
      payload: any;
      from: Participant;
    }) => {
      listener(payload, from);
    };
    eventEmitter.addListener(eventName, handleEvent);
    return () => {
      eventEmitter.removeListener(eventName, handleEvent);
    };
  }, [listener]);

  const sendEvent = useCallback<WebRTCContextType["sendMessageInChunks"]>(
    (eventName: string, data: any, reliable?: boolean) => {
      const payloadString = JSON.stringify(data);
      const encoder = new TextEncoder();
      const byteArray = encoder.encode(payloadString);
      sendMessageInChunks(eventName, byteArray, reliable);
    },
    [sendMessageInChunks]
  );
  return sendEvent;
};

export const useIsWebRTCConnected = () => {
  const room = useRoomContext();
  const connectionState = useConnectionState(room);
  console.log("roomstate", connectionState);
  return connectionState === "connected";
};

export const useBrowserSandboxTracks = () => {
  const tracks = useParticipantTracks(
    [
      Track.Source.Camera,
      Track.Source.Microphone,
      Track.Source.ScreenShare,
      Track.Source.ScreenShareAudio,
      Track.Source.Unknown,
    ],
    ROOM_LIVEKIT_PARTICIPANT_ID
  );
  console.log("tracks", tracks);
  const videoTrack = tracks.find((track) => track.publication.kind === "video");
  const audioTrack = tracks.find((track) => track.publication.kind === "audio");
  return { videoTrack, audioTrack };
};

export const usePeerTracks = (participantId: string | undefined) => {
  const tracks = useParticipantTracks(
    [Track.Source.Camera, Track.Source.Microphone],
    participantId
  );
  const videoTrack = tracks.find((track) => track.publication.kind === "video");
  const audioTrack = tracks.find((track) => track.publication.kind === "audio");
  console.log("peer tracks", videoTrack, audioTrack, participantId);
  return participantId
    ? { videoTrack, audioTrack }
    : { videoTrack: undefined, audioTrack: undefined };
};

export const usePeers = (): TeleoPeer[] => {
  const remoteParticipants = useRemoteParticipants();
  return remoteParticipants.filter(
    (participant) => participant.identity !== ROOM_LIVEKIT_PARTICIPANT_ID
  );
};

export const useSortedParticipants = (): TeleoPeer[] => {
  const peers = usePeers();
  const localParticipant = useLocalParticipant();
  return [...peers, localParticipant.localParticipant]
    .sort((a, b) => {
      const peerARole = a.attributes.role;
      const peerBRole = b.attributes.role;
      if (peerARole === "provider") {
        return 1;
      }
      if (peerBRole === "provider") {
        return -1;
      }

      return a > b ? 1 : -1;
    })
    .reverse();
};

export const useOnParticipantConnect = (
  listener: (participant: Participant) => void
) => {
  const livekitRoom = useRoomContext();
  useEffect(() => {
    const dbgList = (participant: RemoteParticipant) => {
      console.log("onParticipantConnect", participant);
      if (participant.identity !== ROOM_LIVEKIT_PARTICIPANT_ID) {
        listener(participant);
      }
    };
    livekitRoom.on("participantConnected", dbgList);
    return () => {
      livekitRoom.off("participantConnected", dbgList);
    };
  }, [listener]);
};

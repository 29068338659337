import React from "react";

import styles from "pages/Space/components/Videos/VideosButtons/MutedIndicator.module.css";
import { useDispatch, useSelector } from "react-redux";
import { selectIsPeerMuted, setIsPeerMuted } from "redux/settingsRedux";
import Tooltip from "components/Tooltip/Tooltip";
import { ReactComponent as MicOffIcon } from "assets/icons/mic_off.svg";
import clsx from "clsx";
import { selectIsFullScreenVideo } from "redux/spaceNavigationRedux";
import { VideoButton } from "pages/Space/components/Videos/VideosButtons/VideoButton";
import { useTeleoEvent } from "../../ConnectionsContext/teleoPeerEventUtils";
import { useIsTherapist } from "pages/Space/hooks/useIsTherapist";

type MutedIndicatorProps = {
  peerId: string;
  className: string;
  style?: React.CSSProperties;
};

export const MutedIndicator = ({
  peerId,
  className,
  style,
}: MutedIndicatorProps) => {
  const isTherapist = useIsTherapist();
  const isFullScreen = useSelector(selectIsFullScreenVideo);
  const isPeerMuted = useSelector((state) => selectIsPeerMuted(state, peerId));

  const dispatch = useDispatch();

  const emitIsPeerMuted = useTeleoEvent("peer-muted");

  const toggleMute = () => {
    dispatch(setIsPeerMuted({ peerId, isMuted: !isPeerMuted }));
    emitIsPeerMuted({ isPeerMuted: !isPeerMuted, targetPeerId: peerId });
  };

  if (isTherapist) {
    return (
      <VideoButton
        className={className}
        style={style}
        onClick={toggleMute}
        tooltipText={isPeerMuted ? "Unmute" : "Mute"}
        forceVisible={!!isPeerMuted}
        icon={MicOffIcon}
        isIndicator={!!isPeerMuted}
      />
    );
  }

  if (!isPeerMuted) {
    return null;
  }

  return (
    <div className={className} style={style}>
      <Tooltip text={"Muted"}>
        <div
          className={clsx(styles.peerMutedIndicatorButton, {
            [styles.fullScreenButton]: isFullScreen,
          })}
        >
          <MicOffIcon className={styles.icon} />
        </div>
      </Tooltip>
    </div>
  );
};

// A Audio stream component for either a local or remote stream
import React from "react";
import { AudioTrack, TrackReference } from "@livekit/components-react";

type AudioProps = {
  track: TrackReference | undefined;
  muted?: boolean;
};

export const Audio = ({ track, muted }: AudioProps) => {
  return track && <AudioTrack trackRef={track} muted={muted} />;
};

import React, { useState } from "react";

import styles from "./DeleteItemModal.module.css";
import Button from "components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  selectDeleteItemModalData,
  setDeleteItemModalData,
  setEditItemId,
} from "redux/editRoomNavigationRedux";
import Modal from "components/Modal/Modal";
import { useDeleteRoomItemMutation } from "generated/graphql";
import { selectCurrentRoomId } from "redux/userRedux";
import { evictCurrentRoomItems } from "utils/dbUtils";
import { logUnexpectedError } from "utils/errorUtils";
import { useLogRoomItemEvent } from "utils/metricsUtils";
import { selectRoomItems } from "redux/spaceNavigationRedux";
import { useRoomEditingDeleteModalAnalytics } from "pages/Space/subpages/SpaceRoom/hooks/useRoomEditingAnalytics";
import { useTeleoEvent } from "../ConnectionsContext/teleoPeerEventUtils";

const GENERIC_ERROR_MESSAGE =
  "Sorry, there was a problem deleting your item. Please try again later.";

type DeleteItemModalProps = {};

const DeleteItemModal = ({}: DeleteItemModalProps) => {
  const dispatch = useDispatch();
  const roomItems = useSelector(selectRoomItems);
  const roomId = useSelector(selectCurrentRoomId);
  const [deleteItemMutation] = useDeleteRoomItemMutation();
  const [deleting, setDeleting] = useState(false);
  const logRoomItemEvent = useLogRoomItemEvent();
  const { trackDeleteRoomItem } = useRoomEditingDeleteModalAnalytics();

  const deleteItemModalData = useSelector(selectDeleteItemModalData);

  const sendRemoteItemDeleted = useTeleoEvent("room-items-changed");

  const closeModal = () => {
    dispatch(setEditItemId(undefined));
    dispatch(setDeleteItemModalData(undefined));
  };

  const deleteItem = async () => {
    try {
      setDeleting(true);
      if (!deleteItemModalData?.itemId || !roomId) {
        return;
      }

      const itemToDelete = roomItems?.find(
        (item) => item.id === deleteItemModalData.itemId
      );
      const iconId = itemToDelete?.icon_id;
      const resourceId = itemToDelete?.resource.id;

      const { errors } = await deleteItemMutation({
        variables: {
          itemId: deleteItemModalData.itemId,
        },
        update: evictCurrentRoomItems(roomId),
      });
      sendRemoteItemDeleted({});
      if (errors) {
        logUnexpectedError(errors);
        alert(GENERIC_ERROR_MESSAGE);
        return;
      }
      if (itemToDelete) {
        trackDeleteRoomItem(itemToDelete.resource);
      }

      if (iconId && resourceId) {
        logRoomItemEvent({
          action: "DELETE",
          iconId,
          resourceId,
        }).catch(logUnexpectedError);
      }
      dispatch(setEditItemId(undefined));
      closeModal();
    } catch (e) {
      logUnexpectedError(e);
      alert(GENERIC_ERROR_MESSAGE);
    } finally {
      setDeleting(false);
    }
  };

  return (
    <Modal closeModal={closeModal}>
      <div className={styles.heading}>Delete Item</div>
      <div className={styles.body}>
        Are you sure you want to delete this item?
      </div>
      <div className={styles.buttonRow}>
        <Button className={styles.cancelButton} onClick={closeModal}>
          Cancel
        </Button>
        <Button onClick={deleteItem} disabled={deleting}>
          {deleting ? "..." : "Delete"}
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteItemModal;

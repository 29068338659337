import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { OnProgressProps } from "react-player/base";
import ReactPlayer from "react-player/lazy";
import { toast } from "react-toastify";
import clsx from "clsx";

import { selectCanControl } from "redux/settingsRedux";
import { selectUserRole, UserRole } from "redux/userRedux";
import ActivityNavigationHeader from "pages/Space/components/ActivityNavigationHeader/ActivityNavigationHeader";
import styles from "./MediaPlayer.module.css";
import { isMobileDevice } from "utils/deviceUtils";
import { logUnexpectedError } from "utils/errorUtils";
import { useTrackEvent } from "utils/metricsUtils";
import { useTeleoEvent } from "pages/Space/components/ConnectionsContext/teleoPeerEventUtils";

const HEADER_MESSAGE =
  "Only your client can start the video when they are on mobile.";

type MediaPlayerProps = {
  url?: string;
};

const MediaPlayer = ({ url }: MediaPlayerProps) => {
  // keep track of the previous two progress values to determine if the user is seeking
  const secondPreviousProgressRef = useRef<number>(0);
  const previousProgressRef = useRef<number>(0);
  const [hasStarted, setHasStarted] = useState(false);
  const hasStartedRef = useRef(hasStarted);
  const playerRef = useRef<ReactPlayer>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const clientCanControl = useSelector(selectCanControl);
  const userRole = useSelector(selectUserRole);
  const isTherapist = userRole === UserRole.THERAPIST;
  const userCanControl = isTherapist || clientCanControl;
  const userHasOnlyPlayPauseControl =
    !isTherapist && clientCanControl && hasStarted;

  const { trackEvent } = useTrackEvent();
  const eventProperties = { url };

  // Temporary workaround for Soundcloud
  const enablePeriodicSync = useMemo(
    () => url?.includes("soundcloud.com"),
    [url]
  );

  const emitIsPlayingChange = useTeleoEvent("is-playing-change", (payload) => {
    // Mobile device users must start the video before playing/pausing
    if (!hasStartedRef.current && isMobileDevice()) {
      trackEvent(
        "Media player - play/pause before mobile start",
        eventProperties
      );
      return;
    }
    setIsPlaying(!!payload.isPlaying);
  });

  const emitSeekTo = useTeleoEvent("seek-to", (payload) => {
    // Mobile device users must start the video before seeking
    if (!hasStartedRef.current && isMobileDevice()) {
      trackEvent("Media player - seek before mobile start", eventProperties);
      return;
    }
    if (!playerRef.current) {
      logUnexpectedError("Player ref is not set during seek-to event");
      return;
    }
    playerRef.current?.seekTo(payload.time);
  });

  const handleStart = () => {
    setHasStarted(true);
  };

  const handlePlayIfNeeded = () => {
    if (isPlaying) {
      return;
    }
    const currentTime = playerRef.current?.getCurrentTime();
    if (currentTime !== undefined) {
      // avoid sending a floating point number (mainly for less than 1 second)
      const currentTimeInWholeSeconds = Math.floor(currentTime);
      emitSeekTo({ time: currentTimeInWholeSeconds });
      trackEvent("Media player - seek", eventProperties);
    }
    setIsPlaying(true);
    emitIsPlayingChange({ isPlaying: true });
    trackEvent("Media player - play", eventProperties);
  };

  const handlePauseIfNeeded = () => {
    if (!isPlaying) {
      return;
    }
    setIsPlaying(false);
    emitIsPlayingChange({ isPlaying: false });
    trackEvent("Media player - pause", eventProperties);
  };

  const handlePlayPause = () => {
    if (!hasStarted || (hasStarted && !userCanControl)) {
      return;
    }
    if (isPlaying) {
      handlePauseIfNeeded();
    } else {
      handlePlayIfNeeded();
    }
  };

  const handleProgress = (progress: OnProgressProps) => {
    if (
      Math.abs(progress.playedSeconds - previousProgressRef.current) > 2 &&
      previousProgressRef.current - secondPreviousProgressRef.current < 2
    ) {
      emitSeekTo({ time: progress.playedSeconds });
      trackEvent("Media player - seek", {
        url,
        progressPercent: progress.played,
      });
    }
    secondPreviousProgressRef.current = previousProgressRef.current;
    previousProgressRef.current = progress.playedSeconds;
  };

  const handleError = (error: any, data?: any) => {
    toast(
      "Something went wrong. Please try exiting and re-entering the activity.",
      {
        type: "error",
      }
    );
    const errorMessage = error?.message || error;
    logUnexpectedError(
      `Media player error: ${errorMessage} - ${JSON.stringify(data)}`
    );
    trackEvent("Media player - error", { url, error: errorMessage });
  };

  useEffect(() => {
    trackEvent("Media player - viewed", eventProperties);
  }, []);

  useEffect(() => {
    hasStartedRef.current = hasStarted;
  }, [hasStarted]);

  return (
    <div data-testid="media-player" className={styles.container}>
      <div
        className={clsx(styles.navOuterContainer, {
          [styles.outerNoControl]: !userCanControl,
        })}
      >
        <div
          className={clsx(styles.navInnerContainer, {
            [styles.innerNoControl]: !userCanControl,
          })}
        >
          <ActivityNavigationHeader
            isExternal={true}
            message={
              userRole === UserRole.THERAPIST ? HEADER_MESSAGE : undefined
            }
          />
        </div>
      </div>
      <div
        onClick={handlePlayPause}
        className={clsx(styles.playerOuterContainer, {
          [styles.outerNoControl]: !userCanControl && hasStarted,
          [styles.outerOnlyPlayPauseControl]: userHasOnlyPlayPauseControl,
        })}
      >
        <div
          className={clsx(styles.playerContainer, {
            [styles.innerNoControl]: !isTherapist && hasStarted,
          })}
        >
          <ReactPlayer
            url={url}
            playing={isPlaying}
            controls={true}
            height={"100%"}
            width={"100%"}
            ref={playerRef}
            onStart={handleStart}
            onPlay={handlePlayIfNeeded}
            onPause={handlePauseIfNeeded}
            onProgress={enablePeriodicSync ? handleProgress : undefined}
            onError={handleError}
          />
        </div>
      </div>
    </div>
  );
};

export default MediaPlayer;

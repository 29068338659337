import { useDispatch, useSelector } from "react-redux";
import { selectProviderId } from "redux/userRedux";
import {
  setAudioProviderOnly,
  setClientCanControl,
  setIsMuted,
  setIsPeerMuted,
  setIsPeerVideoOff,
  setIsVideoOff,
  setIsWidescreenVideoEnabled,
  setPeerIdWithControl,
  setVideoConferencing,
} from "redux/settingsRedux";
import { useApolloClient } from "@apollo/client";
import { evictCurrentRoom } from "utils/dbUtils";
import { setIsFullScreenVideo } from "redux/spaceNavigationRedux";
import { useTrackEvent } from "utils/metricsUtils";
import { useTeleoEvent } from "../ConnectionsContext/teleoPeerEventUtils";
import { useBoxRef } from "hooks/useBoxRef";
import { getLocalPeerId } from "../ConnectionsContext/WebRTCContext";
import { useIsTherapist } from "pages/Space/hooks/useIsTherapist";

export type RemoteSetters = {};

export const useRemoteSettings = () => {
  const dispatch = useDispatch();
  const providerId = useSelector(selectProviderId);
  const providerIdRef = useBoxRef(providerId);
  const client = useApolloClient();
  const { trackEvent } = useTrackEvent();

  const isTherapist = useIsTherapist();

  useTeleoEvent("client-control", (payload) => {
    if (!isTherapist) {
      dispatch(setClientCanControl(payload.clientCanControl));
    }
  });
  useTeleoEvent("room-changed", () => {
    if (!isTherapist && providerIdRef.current) {
      evictCurrentRoom(providerIdRef.current)(client.cache);
    }
  });
  useTeleoEvent("audio-provider-only", (payload) => {
    if (!isTherapist) {
      dispatch(setAudioProviderOnly(payload.audioProviderOnly));
    }
  });

  useTeleoEvent("video-conferencing", (payload) => {
    if (!isTherapist) {
      dispatch(setVideoConferencing(payload.videoConferencing));
    }
  });
  useTeleoEvent("is-widescreen-video-enabled", (payload) => {
    dispatch(setIsWidescreenVideoEnabled(payload.isWidescreenVideoEnabled));
  });
  useTeleoEvent("full-screen-video-conferencing", (payload) => {
    if (payload.isFullScreen) {
      trackEvent("Video chat - talk mode opened", {
        ["source"]: "peer event",
      });
    } else {
      trackEvent("Video chat - talk mode closed", {
        ["source"]: "peer event",
      });
    }
    dispatch(setIsFullScreenVideo(payload.isFullScreen));
  });
  useTeleoEvent("peer-muted", (payload) => {
    if (payload.targetPeerId === getLocalPeerId()) {
      dispatch(setIsMuted(payload.isPeerMuted));
    } else {
      dispatch(
        setIsPeerMuted({
          peerId: payload.targetPeerId,
          isMuted: payload.isPeerMuted,
        })
      );
    }
  });
  useTeleoEvent("peer-video-off", (payload) => {
    const isVideoOff = payload.isPeerVideoOff;
    const targetId = payload.targetPeerId;
    if (targetId === getLocalPeerId()) {
      dispatch(setIsVideoOff(isVideoOff));
    } else {
      dispatch(setIsPeerVideoOff({ peerId: targetId, isVideoOff }));
    }
  });
  useTeleoEvent("peer-can-control-cursor", (payload) => {
    dispatch(setPeerIdWithControl(payload.targetPeerId));
  });
};

import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import { fabric } from "utils/fabricUtils";
import { fabricTypes } from "utils/fabric-impl";
import { selectCanControlCursor } from "redux/settingsRedux";
import { selectUserRole, UserRole } from "redux/userRedux";
import { store } from "redux/reduxStore";
import { useTeleoEvent } from "pages/Space/components/ConnectionsContext/teleoPeerEventUtils";

export const useRemoteMouseOnCanvas = (
  remoteCursorCanvasRef: React.MutableRefObject<fabricTypes.Canvas | undefined>,
  zoomLevel: number,
  zoomLevelRef: React.MutableRefObject<number>
) => {
  const userRole = useSelector(selectUserRole);
  const remoteCursorRef = useRef<fabricTypes.Image | "pending">();

  useEffect(() => {
    if (!remoteCursorRef.current || remoteCursorRef.current === "pending") {
      return;
    }
    remoteCursorRef.current.set({
      scaleX: 1 / zoomLevel,
      scaleY: 1 / zoomLevel,
    });
    remoteCursorRef.current.setCoords();
    remoteCursorCanvasRef.current?.renderAll();
  }, [zoomLevel]);

  const moveRemoteCursor = (serializedPoint: { x: number; y: number }) => {
    if (!remoteCursorRef.current) {
      remoteCursorRef.current = "pending";
      fabric.Image.fromURL("/cursor.svg", function (oImg: fabricTypes.Image) {
        oImg.set({
          left: serializedPoint.x,
          top: serializedPoint.y,
          height: 20,
          width: 20,
          scaleX: 1 / zoomLevelRef.current,
          scaleY: 1 / zoomLevelRef.current,
        });
        remoteCursorRef.current = oImg;
        remoteCursorCanvasRef.current?.add(oImg);
        remoteCursorCanvasRef.current?.renderAll();
      });
    } else if (remoteCursorRef.current !== "pending") {
      remoteCursorRef.current.set({
        left: serializedPoint.x,
        top: serializedPoint.y,
      });
      remoteCursorRef.current.setCoords();
      remoteCursorCanvasRef.current?.renderAll();
    }
  };

  const removeRemoteCursor = () => {
    if (remoteCursorRef.current && remoteCursorRef.current !== "pending") {
      // TODO: handle case when remoteCursorRef is 'pending'
      remoteCursorCanvasRef.current?.remove(remoteCursorRef.current);
      remoteCursorRef.current = undefined;
    }
  };

  const emitCursorMove = useTeleoEvent("cursor-moved", (payload) => {
    moveRemoteCursor(payload.point);
  });

  const maybeEmitCursorMove = (point: { x: number; y: number }) => {
    const state = store.getState();
    if (userRole === UserRole.THERAPIST || selectCanControlCursor(state)) {
      emitCursorMove({ point });
    }
  };

  const emitCursorOut = useTeleoEvent("cursor-out", () => {
    removeRemoteCursor();
  });

  const maybeEmitCursorOut = () => {
    const state = store.getState();
    if (userRole === UserRole.THERAPIST || selectCanControlCursor(state)) {
      emitCursorOut({});
    }
  };

  return {
    maybeEmitCursorMove,
    maybeEmitCursorOut,
  };
};

import { useLocalParticipant, useRoomContext } from "@livekit/components-react";
import {
  LocalAudioTrack,
  LocalVideoTrack,
  VideoPresets,
  VideoPresets43,
} from "livekit-client";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  selectIsMuted,
  selectIsNoiseSuppressionEnabled,
  selectIsVideoBlurred,
  selectIsVideoOff,
  selectIsWidescreenVideoEnabled,
  selectVideoConferencing,
} from "redux/settingsRedux";
import { useIsWebRTCConnected } from "./WebRTCContext";
import {
  BackgroundBlur,
  BackgroundTransformer,
  ProcessorWrapper,
} from "@livekit/track-processors";

/* eslint new-cap: 0 */

export const INITIAL_VIDEO_PRESET = VideoPresets43.h720;

export const useVideoPreset = () => {
  const isWidescreenVideoEnabled = useSelector(selectIsWidescreenVideoEnabled);
  return useMemo(
    () => (isWidescreenVideoEnabled ? VideoPresets.h720 : VideoPresets43.h720),
    [isWidescreenVideoEnabled]
  );
};

export const useLocalUserMedia = () => {
  const isWebRTCConnected = useIsWebRTCConnected();
  const room = useRoomContext();
  const {
    microphoneTrack: liveKitMicrophoneTrack,
    cameraTrack: liveKitCameraTrack,
    isCameraEnabled: isCameraEnabledInLiveKit,
    isMicrophoneEnabled: isMicrophoneEnabledInLiveKit,
    localParticipant,
  } = useLocalParticipant();
  const isNoiseSuppressionEnabled = useSelector(
    selectIsNoiseSuppressionEnabled
  );
  const isMuted = useSelector(selectIsMuted);
  const isLocalVideoOff = useSelector(selectIsVideoOff);
  const isVideoConferencingEnabled = useSelector(selectVideoConferencing);
  const isBackgroundBlurringEnabled = useSelector(selectIsVideoBlurred);
  const [blurProcessor] = useState(
    ProcessorWrapper.isSupported && BackgroundTransformer.isSupported
      ? BackgroundBlur()
      : undefined
  );

  const desiredVideoPreset = useVideoPreset();

  useEffect(() => {
    if (!isWebRTCConnected) {
      return;
    }
    const audioTrack = liveKitMicrophoneTrack?.audioTrack;
    if (audioTrack && audioTrack instanceof LocalAudioTrack) {
      console.log(
        "audioTrack constraints",
        JSON.stringify(audioTrack.constraints)
      );

      let audioTrackChanged = false;
      if (
        isNoiseSuppressionEnabled !== undefined &&
        audioTrack.constraints.noiseSuppression !== isNoiseSuppressionEnabled
      ) {
        audioTrackChanged = true;
        audioTrack.constraints.noiseSuppression = isNoiseSuppressionEnabled;
      }

      if (audioTrackChanged) {
        console.log(
          "audioTrack constraints changed",
          JSON.stringify(audioTrack.constraints)
        );
        audioTrack.restartTrack();
      }
    }
  }, [liveKitMicrophoneTrack, isNoiseSuppressionEnabled, isWebRTCConnected]);

  useEffect(() => {
    if (!isWebRTCConnected) {
      return;
    }
    const videoTrack = liveKitCameraTrack?.videoTrack;
    if (
      isCameraEnabledInLiveKit &&
      videoTrack &&
      videoTrack instanceof LocalVideoTrack
    ) {
      console.log(
        "videoTrack constraints",
        JSON.stringify(videoTrack.constraints)
      );

      if (blurProcessor) {
        if (isBackgroundBlurringEnabled === true) {
          if (!videoTrack.getProcessor()) {
            videoTrack.setProcessor(blurProcessor);
          }
        } else {
          if (videoTrack.getProcessor()) {
            videoTrack.stopProcessor();
          }
        }
      } else {
        console.log("Background blur not supported");
      }

      let videoTrackChanged = false;

      if (
        videoTrack.constraints.width !== desiredVideoPreset.width ||
        videoTrack.constraints.height !== desiredVideoPreset.height ||
        videoTrack.constraints.aspectRatio !== desiredVideoPreset.aspectRatio
      ) {
        videoTrackChanged = true;
      }

      Object.assign(videoTrack.constraints, desiredVideoPreset);

      if (videoTrackChanged) {
        console.log(
          "videoTrack constraints changed",
          JSON.stringify(videoTrack.constraints)
        );
        videoTrack.restartTrack();
      }
    }
  }, [
    liveKitCameraTrack,
    isCameraEnabledInLiveKit,
    desiredVideoPreset,
    isWebRTCConnected,
    isBackgroundBlurringEnabled,
  ]);

  useEffect(() => {
    if (room.options.audioCaptureDefaults) {
      if (isNoiseSuppressionEnabled !== undefined) {
        console.log("setting audio capture defaults");
        room.options.audioCaptureDefaults.noiseSuppression =
          isNoiseSuppressionEnabled;
      }
    }
  }, [room, isNoiseSuppressionEnabled]);

  useEffect(() => {
    console.log(
      "update video capture defaults",
      room.options.videoCaptureDefaults
    );
    if (room.options.videoCaptureDefaults) {
      console.log("setting video capture defaults");
      room.options.videoCaptureDefaults.resolution =
        desiredVideoPreset.resolution;
    }
  }, [room, desiredVideoPreset]);

  const audioTrack = liveKitMicrophoneTrack?.audioTrack;

  useEffect(() => {
    if (!isWebRTCConnected) {
      return;
    }
    if (
      isMuted !== undefined &&
      audioTrack &&
      audioTrack instanceof LocalAudioTrack
    ) {
      if (isMuted) {
        if (!audioTrack.isMuted) {
          console.log("muting audio track");
          audioTrack.mute();
        }
      } else {
        if (audioTrack.isMuted) {
          console.log("unmuting audio track");
          audioTrack.unmute();
        }
      }
    }
  }, [audioTrack, isMuted, isWebRTCConnected]);

  useEffect(() => {
    if (!isWebRTCConnected) {
      return;
    }
    console.log("isVideoConferencingEnabled", isVideoConferencingEnabled);
    console.log(
      "livekitParticipant",
      isCameraEnabledInLiveKit,
      isMicrophoneEnabledInLiveKit
    );
    if (!isVideoConferencingEnabled) {
      if (isCameraEnabledInLiveKit) {
        localParticipant.setCameraEnabled(false);
      }
      if (isMicrophoneEnabledInLiveKit) {
        localParticipant.setMicrophoneEnabled(false);
      }
      return;
    }

    if (isCameraEnabledInLiveKit !== !isLocalVideoOff) {
      localParticipant.setCameraEnabled(!isLocalVideoOff);
    }

    if (!isMicrophoneEnabledInLiveKit && !isMuted) {
      localParticipant.setMicrophoneEnabled(true);
    }
  }, [
    isCameraEnabledInLiveKit,
    isMicrophoneEnabledInLiveKit,
    localParticipant,
    isLocalVideoOff,
    isVideoConferencingEnabled,
    isWebRTCConnected,
    isMuted,
  ]);
};

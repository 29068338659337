import React from "react";

import { Video } from "./Video";
import { Audio } from "./Audio";
import styles from "./VideoOrError.module.css";
import clsx from "clsx";
import { selectOrganizationId, UserRole } from "redux/userRedux";
import { useDispatch, useSelector } from "react-redux";
import Button from "components/Button/Button";
import LoadingAnimation from "components/LoadingAnimation/LoadingAnimation";
import { selectShowVideoBlurLoading } from "redux/spaceNavigationRedux";
import { MutedIndicator } from "./VideosButtons/MutedIndicator";
import { DisableClientVideoButton } from "./VideosButtons/DisableClientVideoButton";
import {
  selectIsPeerVideoOff,
  selectIsVideoOff,
  setIsVideoOff,
} from "redux/settingsRedux";
import { MediaMenuButton } from "./VideosButtons/MediaMenuButton";
import { MuteButton } from "./VideosButtons/MuteButton";
import { isMobileDevice } from "utils/deviceUtils";
import { BlurButton } from "./VideosButtons/BlurButton";
import { ExpandButton } from "./VideosButtons/ExpandButton";
import { CanControlCursorButton } from "./VideosButtons/CanControlCursorButton";
import { ConnectionQualityIndicator } from "./VideosButtons/ConnectionQualityIndicator";
import { getOrganizationConfig } from "utils/organizationUtils";
import { TeleoPeer, usePeerTracks } from "../ConnectionsContext/WebRTCContext";
import { useIsTherapist } from "pages/Space/hooks/useIsTherapist";
import { useTeleoEvent } from "../ConnectionsContext/teleoPeerEventUtils";

type VideoOrErrorProps = {
  participant: TeleoPeer | "placeholder";
  isFullScreen?: boolean;
  setRawVideoDimensions: ({
    width,
    height,
  }: {
    width: number;
    height: number;
  }) => void;
  peerFullScreenDimensions: { width: number; height: number };
  localFullScreenDimensions: { width: number; height: number };
  parentDimensions: { width: number; height: number };
  isLastPeer?: boolean;
  peerCount: number;
  showExpandButton?: boolean;
};

// const addBreadcrumb = getAddBreadcrumb("videoChat");

export const VideoOrError = ({
  participant,
  isFullScreen,
  setRawVideoDimensions,
  peerFullScreenDimensions,
  localFullScreenDimensions,
  parentDimensions,
  isLastPeer,
  peerCount,
  showExpandButton,
}: VideoOrErrorProps) => {
  const dispatch = useDispatch();

  const isPlaceholder = participant === "placeholder";
  const isPeerVideoOff = useSelector((state) =>
    selectIsPeerVideoOff(state, isPlaceholder ? "" : participant.identity)
  );
  const isLocalVideoOff = useSelector(selectIsVideoOff);

  const showVideoBlurLoading = useSelector(selectShowVideoBlurLoading);

  const organizationId = useSelector(selectOrganizationId);
  const organizationConfig = getOrganizationConfig(organizationId);
  const { videoTrack, audioTrack } = usePeerTracks(
    isPlaceholder ? undefined : participant.identity
  );

  const isLocalUserTherapist = useIsTherapist();

  const emitIsPeerVideoOff = useTeleoEvent("peer-video-off");

  if (isPlaceholder && !isFullScreen) {
    return null;
  }

  const isLocal = !isPlaceholder && participant.isLocal;
  const isRemotePeer = !isPlaceholder && !participant.isLocal;

  const userRole = isPlaceholder
    ? isLocalUserTherapist
      ? UserRole.CLIENT
      : UserRole.THERAPIST
    : participant.attributes.role === "provider"
    ? UserRole.THERAPIST
    : UserRole.CLIENT;

  const hasVideo = !!videoTrack;

  // TODO: confirm if livekit handle local video feedback loop
  // never show audio for local user to avoid audio feedback loop
  // const hasAudio = !isLocal && !!audioTrack;
  const hasAudio = !!audioTrack;

  const getMessage = () => {
    if ((isRemotePeer && isPeerVideoOff) || (isLocal && isLocalVideoOff)) {
      return "Camera off";
    }

    // TODO: Check if livekit has connecting state
    // if (peer?.isConnecting) {
    //   return "Connecting...";
    // }

    // if (error) {
    //   return error;
    // }

    // TODO: check if when peer is closing their browser, if we get video tracks muted
    // When there is a video stream but before the peer gives camera permission
    // or when they close the browser window, right before isConnecting changes
    // if (
    //   hasVideo &&
    //   !isLocal &&
    //   stream?.getVideoTracks().every((track) => track.muted)
    // ) {
    //   addBreadcrumb("debug", "stream has video muted", {
    //     tracks: stream.getTracks().map((track) => ({
    //       kind: track.kind,
    //       muted: track.muted,
    //       id: track.id,
    //     })),
    //   });
    //   return "Connecting...";
    // }

    // if (hasVideo || (isLocal && !error)) {
    // if (hasVideo || isLocal) {
    if (hasVideo) {
      return null;
    }
    const videoIsClient = userRole !== UserRole.THERAPIST;
    // TODO: check if livekit has peer connected state
    // if (videoIsClient && !isPlaceholder && ) {
    //   return "Client is not connected";
    // }

    return `No ${videoIsClient ? "client" : "therapist"} video`;
  };
  const message = getMessage();

  const containerStyle: React.CSSProperties | undefined = isFullScreen
    ? {
        width: isLocal
          ? localFullScreenDimensions.width
          : peerFullScreenDimensions.width,
        height: isLocal
          ? localFullScreenDimensions.height
          : peerFullScreenDimensions.height,
        bottom: isLocal
          ? parentDimensions.height / 4 - peerFullScreenDimensions.height / 4
          : undefined,
        left: isLocal
          ? (peerCount ?? 1) > 2
            ? "50%"
            : Math.max(
                parentDimensions.width / 2 - peerFullScreenDimensions.width / 2,
                localFullScreenDimensions.width / 2 + 30
              )
          : undefined,

        transform: isLocal ? "translateX(-50%)" : undefined,
        margin: isLocal ? undefined : "0 14px",
      }
    : undefined;

  const enableVideoClickHandler = () => {
    if (isPlaceholder) {
      return;
    }
    if (isLocal) {
      dispatch(setIsVideoOff(false));
      emitIsPeerVideoOff({
        isPeerVideoOff: false,
        targetPeerId: participant.identity,
      });
    } else {
      if (isLocalUserTherapist) {
        emitIsPeerVideoOff({
          isPeerVideoOff: false,
          targetPeerId: participant.identity,
        });
      }
    }
  };

  const shouldShowEnableVideoButton =
    message === "Camera off" && isLocal && !isLocalUserTherapist;
  const shouldShowReloadUserMediaButton =
    isLocal && !!message && message !== "Camera off";

  // Don't show the blur button on mobile devices because the blurring is too resource intensive to run on mobile
  const isMobile = isMobileDevice();

  const isBackgroundBlurDisabled =
    isMobile ||
    (!isLocalUserTherapist &&
      organizationConfig?.isClientBackgroundBlurDisabled);

  return (
    <div
      className={clsx(styles.container, "singleVideoContainer", {
        [styles.localContainer]: isLocal,
        ["fullScreenLocalVideo"]: isLocal && isFullScreen,
        [styles.lastPeer]: isLastPeer,
      })}
      style={containerStyle}
    >
      {/* TODO: Check how livekit handles loading user media and maybe remove this LoadingAnimation
      {loadingUserMedia ? ( */}
      {false ? (
        <LoadingAnimation />
      ) : message ? (
        <div
          className={clsx(styles.message, {
            [styles.fullScreenMessage]: isFullScreen && !isLocal,
          })}
        >
          {message}
          <Audio track={audioTrack} />
          {shouldShowEnableVideoButton ? (
            <Button onClick={enableVideoClickHandler} className={styles.button}>
              Turn on
            </Button>
          ) : (
            shouldShowReloadUserMediaButton && (
              // TODO: replace with livekit's user media selection
              // <Button onClick={reloadUserMedia} className={styles.button}>
              <Button className={styles.button}>Retry</Button>
            )
          )}
        </div>
      ) : (
        <>
          <Video
            track={videoTrack}
            isLocal={isLocal}
            setRawVideoDimensions={setRawVideoDimensions}
          />
          {hasAudio && <Audio track={audioTrack} />}
        </>
      )}
      <div className={styles.buttonsWrapper}>
        {!isPlaceholder && !isLocal && (
          <MutedIndicator
            peerId={participant.identity}
            className={clsx(styles.buttonContainer, styles.mutedIndicator)}
          />
        )}
        {isLocal && (
          <>
            <MediaMenuButton
              className={clsx(styles.buttonContainer, styles.mediaMenuButton)}
            />
            <MuteButton
              className={clsx(styles.buttonContainer, styles.muteButton)}
            />
            {!isBackgroundBlurDisabled && (
              <BlurButton
                className={clsx(styles.buttonContainer, styles.blurButton)}
              />
            )}
            <ConnectionQualityIndicator
              className={clsx(
                styles.buttonContainer,
                styles.connectionQualityIndicator
              )}
            />
          </>
        )}
        {isLocalUserTherapist && !isPlaceholder && (
          <DisableClientVideoButton
            peerId={participant.identity}
            className={clsx(styles.buttonContainer, styles.disableVideoButton)}
          />
        )}
        {peerCount > 2 && userRole === UserRole.CLIENT && !isPlaceholder && (
          <CanControlCursorButton
            peerId={participant.identity}
            className={clsx(
              styles.buttonContainer,
              styles.canControlCursorButton
            )}
          />
        )}
      </div>
      {isLocal && showVideoBlurLoading && (
        <div className={styles.blurLoadingIndicator}>
          <LoadingAnimation />
        </div>
      )}
      {showExpandButton && (
        <ExpandButton className={styles.expandButtonContainer} />
      )}
    </div>
  );
};

import React, { useState } from "react";

import styles from "./DeleteRoomModal.module.css";
import Button from "components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import Modal from "components/Modal/Modal";
import {
  selectCurrentRoomId,
  selectOrganizationId,
  selectUserId,
  setCurrentRoomId,
} from "redux/userRedux";
import { evictCurrentRoom } from "utils/dbUtils";
import { logUnexpectedError } from "utils/errorUtils";
import {
  GetOpenRoomModalDataDocument,
  GetRoomMenuDataDocument,
  useDeleteRoomMutation,
} from "generated/graphql";
import {
  selectDeleteRoomModalRoom,
  setDeleteRoomModalRoom,
} from "redux/spaceNavigationRedux";
import { setEditItemId } from "redux/editRoomNavigationRedux";
import { useTeleoEvent } from "../ConnectionsContext/teleoPeerEventUtils";

const GENERIC_ERROR_MESSAGE =
  "Sorry, there was a problem deleting your room. Please try again later.";

type DeleteRoomModalProps = {};

const DeleteRoomModal = ({}: DeleteRoomModalProps) => {
  const dispatch = useDispatch();
  const room = useSelector(selectDeleteRoomModalRoom);
  const currentRoomId = useSelector(selectCurrentRoomId);
  const userId = useSelector(selectUserId);
  const organizationId = useSelector(selectOrganizationId);
  const [deleteRoomMutation] = useDeleteRoomMutation();
  const [deleting, setDeleting] = useState(false);

  const sendRemoteRoomChanged = useTeleoEvent("room-items-changed");

  const closeModal = () => {
    dispatch(setDeleteRoomModalRoom(null));
  };

  const deleteRoom = async () => {
    try {
      setDeleting(true);
      if (!room) {
        return;
      }

      const isCurrentRoom = room.id === currentRoomId;

      const { errors } = await deleteRoomMutation({
        variables: {
          roomId: room.id,
        },
        update: isCurrentRoom ? evictCurrentRoom(userId) : undefined,
        refetchQueries: [
          ...(isCurrentRoom
            ? [{ query: GetRoomMenuDataDocument, variables: { userId } }]
            : []),
          {
            query: GetOpenRoomModalDataDocument,
            variables: {
              userId,
              hasOrganizationId: !!organizationId,
              organizationId,
            },
          },
        ],
      });
      if (errors) {
        logUnexpectedError(errors);
        alert(GENERIC_ERROR_MESSAGE);
        return;
      }

      if (isCurrentRoom) {
        sendRemoteRoomChanged({});
        dispatch(setCurrentRoomId(null));
        dispatch(setEditItemId(undefined));
      }
      closeModal();
    } catch (e) {
      logUnexpectedError(e);
      alert(GENERIC_ERROR_MESSAGE);
    } finally {
      setDeleting(false);
    }
  };

  if (!room) {
    return null;
  }

  return (
    <Modal closeModal={closeModal}>
      <div className={styles.heading}>Delete Room</div>
      <div className={styles.body}>
        {`Are you sure you want to delete the room "${room.name}"?`}
      </div>
      <div className={styles.buttonRow}>
        <Button className={styles.cancelButton} onClick={closeModal}>
          Cancel
        </Button>
        <Button onClick={deleteRoom} disabled={deleting}>
          {deleting ? "..." : "Delete"}
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteRoomModal;

// A video stream component for either a local or remote stream

import React, { useLayoutEffect, useRef } from "react";
import clsx from "clsx";

import styles from "./Video.module.css";
import { TrackReference, VideoTrack } from "@livekit/components-react";

type VideoProps = {
  track: TrackReference | undefined;
  isLocal?: boolean;
  setRawVideoDimensions: ({
    width,
    height,
  }: {
    width: number;
    height: number;
  }) => void;
};

export const Video = ({
  track,
  isLocal,
  setRawVideoDimensions,
}: VideoProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  const updateRawDimensions = () => {
    setRawVideoDimensions({
      width: videoRef.current?.videoWidth || 0,
      height: videoRef.current?.videoHeight || 0,
    });
  };

  useLayoutEffect(() => {
    updateRawDimensions();
    videoRef.current?.addEventListener("resize", updateRawDimensions);

    return () => {
      videoRef.current?.removeEventListener("resize", updateRawDimensions);
    };
  }, [videoRef, setRawVideoDimensions]);

  return (
    track && (
      <VideoTrack
        trackRef={track}
        className={clsx(styles.video, { [styles.localVideo]: isLocal })}
        ref={videoRef}
        playsInline
        disablePictureInPicture
      />
    )
  );
};

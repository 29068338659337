import React, { useState } from "react";

import styles from "./OpenRoomModal.module.css";
import Button from "components/Button/Button";
import Modal from "components/Modal/Modal";
import {
  GetRoomMenuDataDocument,
  useGetOpenRoomModalDataQuery,
  useSetCurrentRoomMutation,
} from "generated/graphql";
import OpenRoomRow from "./OpenRoomRow";
import OpenSharedRoomFolderRow from "./OpenSharedRoomFolderRow";
import { useDispatch, useSelector } from "react-redux";
import { setShowOpenRoomModal } from "redux/spaceNavigationRedux";
import {
  selectCurrentRoomId,
  selectOrganizationId,
  selectUserId,
  setCurrentRoomId,
} from "redux/userRedux";
import { evictCurrentRoom } from "utils/dbUtils";
import { useTrackEvent } from "utils/metricsUtils";
import { setEditItemId } from "redux/editRoomNavigationRedux";
import { useTeleoEvent } from "../../ConnectionsContext/teleoPeerEventUtils";

type OpenRoomModalProps = {};

const OpenRoomModal = ({}: OpenRoomModalProps) => {
  const [selectedRoomId, setSelectedRoomId] = useState<string>();
  const userId = useSelector(selectUserId);
  const organizationId = useSelector(selectOrganizationId);
  const currentRoomId = useSelector(selectCurrentRoomId);
  const dispatch = useDispatch();
  const { trackEvent } = useTrackEvent();
  const [setCurrentRoom] = useSetCurrentRoomMutation();
  const { data: openRoomModalData } = useGetOpenRoomModalDataQuery({
    variables: {
      userId: userId || "",
      hasOrganizationId: !!organizationId,
      organizationId: organizationId,
    },
    skip: !userId,
  });

  const emitRoomChanged = useTeleoEvent("room-changed");

  const closeModal = () => {
    dispatch(setShowOpenRoomModal(false));
  };

  const openSelectedRoom = async () => {
    if (!selectedRoomId) {
      return;
    }
    await openRoom(selectedRoomId);
  };

  const openRoom = async (roomId: string) => {
    if (!userId) {
      return;
    }

    await setCurrentRoom({
      variables: { userId, roomId },
      update: evictCurrentRoom(userId),
      refetchQueries: [
        { query: GetRoomMenuDataDocument, variables: { userId } },
      ],
    });
    emitRoomChanged({});
    dispatch(setCurrentRoomId(roomId));
    dispatch(setEditItemId(undefined));
    trackEvent("Room Switch");
    closeModal();
  };

  return (
    <Modal closeModal={closeModal}>
      <div className={styles.heading}>Open Room</div>
      <div className={styles.roomRowsContainer}>
        {openRoomModalData?.sharedRoomFolders?.map((sharedRoomFolder) => (
          <OpenSharedRoomFolderRow
            key={sharedRoomFolder.id}
            sharedRoomFolder={sharedRoomFolder}
          />
        ))}
        {openRoomModalData?.providerRooms.map((room) => (
          <OpenRoomRow
            key={room.id}
            room={room}
            onClick={() => {
              setSelectedRoomId(room.id);
            }}
            onDoubleClick={() => {
              openRoom(room.id);
            }}
            isSelected={selectedRoomId === room.id}
            isCurrentRoom={currentRoomId === room.id}
          />
        ))}
      </div>
      <Button onClick={openSelectedRoom} disabled={!selectedRoomId}>
        Open
      </Button>
    </Modal>
  );
};

export default OpenRoomModal;

import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  pushWaitingRoomAlertClients,
  selectWaitingRoomAlertClients,
} from "redux/clientManagementRedux";
import {
  GetWaitingRoomParticipantsQuery,
  useGetWaitingRoomNamesQuery,
  useGetWaitingRoomParticipantsQuery,
} from "generated/graphql";
import { selectUserId } from "redux/userRedux";
import { WaitingRoomAlert } from "./WaitingRoomAlert";
import { SharedResourceAlert } from "./SharedResourceAlert";
import styles from "./Alerts.module.css";
import {
  selectCurrentResourceId,
  selectMeetingID,
} from "redux/spaceNavigationRedux";
import { useWaitingRoomNotification } from "./useWaitingRoomNotification";

type AlertsProps = {};

export const Alerts = ({}: AlertsProps) => {
  const userId = useSelector(selectUserId);
  const meetingID = useSelector(selectMeetingID);
  const waitingRoomAlertClients = useSelector(selectWaitingRoomAlertClients);
  const currentResourceId = useSelector(selectCurrentResourceId);
  const previousParticipantsRef = useRef<
    GetWaitingRoomParticipantsQuery | undefined
  >();
  const dispatch = useDispatch();

  const { data: allParticipantsData } = useGetWaitingRoomParticipantsQuery({
    variables: {
      meetingID: meetingID ?? "",
    },
    skip: !meetingID || !userId,
  });

  // Compare the previous list of participants with the current list and
  // add the new waiting room participants to the alert list
  useEffect(() => {
    if (!!allParticipantsData?.waiting_room_participant.length) {
      const previousParticipantsSet = new Set(
        previousParticipantsRef.current?.waiting_room_participant?.map(
          (participant) => participant.client_id
        ) || []
      );

      const newParticipants =
        allParticipantsData.waiting_room_participant.filter(
          (participant) => !previousParticipantsSet.has(participant.client_id)
        );
      dispatch(
        pushWaitingRoomAlertClients(
          newParticipants.map((participant) => participant.client_id)
        )
      );
    }
    previousParticipantsRef.current = allParticipantsData;
  }, [allParticipantsData]);

  const { data, previousData } = useGetWaitingRoomNamesQuery({
    variables: {
      meetingID: meetingID ?? "",
      secretClientIds: waitingRoomAlertClients,
    },
    skip: !meetingID || !userId,
  });

  const dataOrPreviousData = data ?? previousData;

  useWaitingRoomNotification(
    dataOrPreviousData?.waiting_room_participant.length ?? 0
  );

  if (!userId) {
    return null;
  }

  return (
    <div className={styles.container}>
      {dataOrPreviousData?.waiting_room_participant.map((participant) => {
        const clientId = participant.client_id;
        const name = participant.client_name;
        return (
          <WaitingRoomAlert
            key={clientId}
            secretClientId={clientId}
            name={name}
          />
        );
      })}
      {currentResourceId && (
        <SharedResourceAlert resourceId={currentResourceId} />
      )}
    </div>
  );
};

import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";

import styles from "./MultiColorButton.module.css";
import ColorPicker from "./ColorPicker";
import { getPositionForColor } from "./colorPickerHelpers";
import { selectCustomColors, setCustomColor } from "redux/spaceNavigationRedux";
import { useTrackWhiteboardCustomColorMenu } from "./useWhiteboardAnalytics";
import { useBoxRef } from "hooks/useBoxRef";
import { useTeleoEvent } from "pages/Space/components/ConnectionsContext/teleoPeerEventUtils";

interface MultiColorButtonProps {
  id: string;
  isSelected: boolean;
  onClick: () => void;
  updateCanvasBrushColor: (color: string) => void;
  buttonStyle: {
    height: number;
    width: number;
    borderRadius: number;
    fontSize: number;
    outlineWidth: number;
  };
  containerRef: React.RefObject<HTMLDivElement>;
}

export const MultiColorButton = ({
  id,
  isSelected,
  onClick,
  updateCanvasBrushColor,
  buttonStyle,
  containerRef,
}: MultiColorButtonProps) => {
  const dispatch = useDispatch();
  const [showPicker, setShowPicker] = useState(false);
  const customColors = useSelector(selectCustomColors);
  const selectedColor = customColors[id];
  const [indicatorPosition, setIndicatorPosition] = useState(
    getPositionForColor(selectedColor)
  );
  const [isColorChangeDisabled, setIsColorChangeDisabled] = useState(false);
  const buttonContainerRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const remoteColorChangeTimeout = useRef<NodeJS.Timeout>();
  const trackColorSelectedTimeout = useRef<NodeJS.Timeout>();
  const isSelectedRef = useBoxRef(isSelected);

  const { trackMenuOpened, trackMenuClosed, trackColorSelected } =
    useTrackWhiteboardCustomColorMenu(id);

  const trackAndSetShowPicker = (showPicker: boolean) => {
    if (showPicker) {
      trackMenuOpened();
    } else {
      trackMenuClosed();
    }
    setShowPicker(showPicker);
  };

  const tempDisableColorChange = () => {
    clearTimeout(remoteColorChangeTimeout.current);
    setIsColorChangeDisabled(true);
    remoteColorChangeTimeout.current = setTimeout(() => {
      setIsColorChangeDisabled(false);
    }, 500);
  };

  const emitCustomColorChanged = useTeleoEvent(
    "custom-color-changed",
    (payload) => {
      if (payload.colorId === id) {
        tempDisableColorChange();
        dispatch(setCustomColor({ id, color: payload.color }));
        setIndicatorPosition(payload.position);
        if (isSelectedRef.current) {
          updateCanvasBrushColor(payload.color);
        }
      }
    }
  );

  const handleMultiColorButtonClick = () => {
    onClick();
    updateCanvasBrushColor(selectedColor);
    if (showPicker) {
      trackAndSetShowPicker(false);
    } else if (isSelected) {
      trackAndSetShowPicker(true);
    }
  };

  const handleColorPickerIconButtonClick = () => {
    trackAndSetShowPicker(!showPicker);
    onClick();
    updateCanvasBrushColor(selectedColor);
  };

  const handleSetSelectedColor = (color: string, position: number) => {
    clearTimeout(trackColorSelectedTimeout.current);
    trackColorSelectedTimeout.current = setTimeout(() => {
      trackColorSelected(color);
    }, 500);
    dispatch(setCustomColor({ id, color }));
    updateCanvasBrushColor(color);
    emitCustomColorChanged({ colorId: id, color, position });
  };

  const handleClickOrTapOutside = (event: MouseEvent | TouchEvent) => {
    // Close the color picker if the user clicks within the control button container
    // Don't close the color picker if the user is drawing since they might want to adjust the color they're using while drawing
    if (
      containerRef.current &&
      buttonContainerRef.current &&
      containerRef.current.contains(event.target as Node) &&
      !buttonContainerRef.current.contains(event.target as Node)
    ) {
      trackAndSetShowPicker(false);
    }
  };

  React.useEffect(() => {
    if (showPicker) {
      document.addEventListener("mousedown", handleClickOrTapOutside);
      document.addEventListener("touchstart", handleClickOrTapOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOrTapOutside);
      document.removeEventListener("touchstart", handleClickOrTapOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOrTapOutside);
      document.removeEventListener("touchstart", handleClickOrTapOutside);
    };
  }, [showPicker]);

  return (
    <div ref={buttonContainerRef} className={styles.buttonContainer}>
      <button
        id="multiColorButton"
        ref={buttonRef}
        className={clsx(styles.button, {
          [styles.isSelected]: isSelected,
        })}
        style={{ ...buttonStyle, backgroundColor: selectedColor }}
        onClick={handleMultiColorButtonClick}
      />
      <button
        id="multiColorPickerButton"
        className={clsx(styles.rainbowCircle, { [styles.isOpen]: showPicker })}
        style={{
          width: buttonStyle.width * 0.55,
          height: buttonStyle.height * 0.55,
          outlineWidth: buttonStyle.outlineWidth * 0.7,
        }}
        onClick={handleColorPickerIconButtonClick}
      />
      <ColorPicker
        open={showPicker}
        setOpen={trackAndSetShowPicker}
        selectedColor={selectedColor}
        setSelectedColor={handleSetSelectedColor}
        indicatorPosition={indicatorPosition}
        setIndicatorPosition={setIndicatorPosition}
        isColorChangeDisabled={isColorChangeDisabled}
        referenceElement={buttonRef.current}
      />
    </div>
  );
};

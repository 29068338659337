import { createSlice } from "@reduxjs/toolkit";
import { GetOpenRoomModalDataQuery, RoomItemFragment } from "generated/graphql";
import { COLORS } from "teleoConstants";

export enum SpacePage {
  ROOM,
  WHITEBOARD,
  BROWSER_SANDBOX,
  MEDIA_PLAYER,
  ALBUM,
}

export type SnapshotTask = {
  id: number;
  status: "created" | "generating" | "saving" | "saved";
  image?: File;
};

export interface SpaceNavigationState {
  meetingID?: string;
  currentPage: SpacePage;
  currentResourceId?: string;
  currentRoomItemId?: string;
  browserSandboxUrl?: string;
  browserSandboxInitialize?: boolean;
  whiteboardBackground?: string;
  customColors: { [id: string]: string };
  roomItems?: RoomItemFragment[];
  thumbnailSrcs: {
    [fileKey: string]: string;
  };
  backgroundSize?: { width: number; height: number };
  backgroundId?: string;
  showRoomCustomizationActivityModal?: boolean;
  showSourceModal?: boolean;
  showInviteModal?: boolean;
  showSettingsModal?: boolean;
  showTimeoutWarningModal?: boolean;
  showOpenRoomModal?: boolean;
  shareRoomModalRoomId?: string;
  deleteRoomModalRoom?: GetOpenRoomModalDataQuery["providerRooms"][0];
  deleteSharedRoomModalSharedRoom?: {
    id: string;
    name: string;
    sharedRoomFolderId: string;
  };
  sharedRoomFolderModalFolderId?: string;
  isFullScreenVideo?: boolean;
  isLaunchActivitySideBarOpen: boolean;
  hasLaunchedActivityFromFullScreenVideo?: boolean;
  snapshotTasks: SnapshotTask[];
  showVideoBlurLoading?: boolean;
}

export interface RootState {
  spaceNavigation: SpaceNavigationState;
}

const INITIAL_STATE = {
  currentPage: SpacePage.ROOM,
  customColors: {
    CUSTOM_COLOR_1: COLORS.ORANGE,
    CUSTOM_COLOR_2: COLORS.PURPLE,
  },
  thumbnailSrcs: {},
  takeSnapshot: false,
  snapshotTasks: [],
  isLaunchActivitySideBarOpen: true,
} as SpaceNavigationState;

export const spaceNavigationSlice = createSlice({
  name: "spaceNavigation",
  initialState: INITIAL_STATE,
  reducers: {
    resetNavigation: (state) => {
      state.currentPage = INITIAL_STATE.currentPage;
      state.currentResourceId = INITIAL_STATE.currentResourceId;
      state.currentRoomItemId = INITIAL_STATE.currentRoomItemId;
      state.browserSandboxUrl = INITIAL_STATE.browserSandboxUrl;
      state.browserSandboxInitialize = INITIAL_STATE.browserSandboxInitialize;
      state.whiteboardBackground = INITIAL_STATE.whiteboardBackground;
      state.customColors = INITIAL_STATE.customColors;
      state.showRoomCustomizationActivityModal =
        INITIAL_STATE.showRoomCustomizationActivityModal;
    },
    resetExceptMeetingID: (state) => {
      return {
        ...INITIAL_STATE,
        meetingID: state.meetingID,
      };
    },
    setMeetingID: (state, value) => {
      state.meetingID = value.payload;
    },
    setCurrentPage: (state, value) => {
      state.currentPage = value.payload;
    },
    setCurrentResourceId: (state, value) => {
      state.currentResourceId = value.payload;
    },
    setCurrentRoomItemId: (state, value) => {
      state.currentRoomItemId = value.payload;
    },
    setBrowserSandboxUrl: (state, value) => {
      state.browserSandboxUrl = value.payload;
    },
    setBrowserSandboxInitialize: (state, value) => {
      state.browserSandboxInitialize = value.payload;
    },
    setWhiteboardBackground: (state, value) => {
      state.whiteboardBackground = value.payload;
    },
    setCustomColors: (state, value) => {
      state.customColors = value.payload;
    },
    setCustomColor: (state, value) => {
      state.customColors = {
        ...state.customColors,
        [value.payload.id]: value.payload.color,
      };
    },
    setRoomItems: (state, value) => {
      state.roomItems = value.payload;
    },
    setThumbnailSrcs: (
      state,
      { payload }: { payload: SpaceNavigationState["thumbnailSrcs"] }
    ) => {
      state.thumbnailSrcs = payload;
    },
    setBackgroundSize: (state, value) => {
      state.backgroundSize = value.payload;
    },
    setBackgroundId: (state, value) => {
      state.backgroundId = value.payload;
    },
    setShowRoomCustomizationActivityModal: (state, value) => {
      state.showRoomCustomizationActivityModal = value.payload;
    },
    setShowSourceModal: (state, value) => {
      state.showSourceModal = value.payload;
    },
    setShowInviteModal: (state, value) => {
      state.showInviteModal = value.payload;
    },
    setShowSettingsModal: (state, value) => {
      state.showSettingsModal = value.payload;
    },
    setShowTimeoutWarningModal: (state, value) => {
      state.showTimeoutWarningModal = value.payload;
    },
    setShowOpenRoomModal: (state, value) => {
      state.showOpenRoomModal = value.payload;
    },
    setShareRoomModalRoomId: (state, value) => {
      state.shareRoomModalRoomId = value.payload;
    },
    setDeleteRoomModalRoom: (state, value) => {
      state.deleteRoomModalRoom = value.payload;
    },
    setDeleteSharedRoomModalSharedRoom: (state, value) => {
      state.deleteSharedRoomModalSharedRoom = value.payload;
    },
    setSharedRoomFolderModalFolderId: (state, value) => {
      state.sharedRoomFolderModalFolderId = value.payload;
    },
    setIsFullScreenVideo: (
      state,
      {
        payload,
      }: {
        payload: SpaceNavigationState["isFullScreenVideo"];
      }
    ) => {
      state.isFullScreenVideo = payload;
    },
    setHasLaunchedActivityFromFullScreenVideo: (
      state,
      {
        payload,
      }: {
        payload: SpaceNavigationState["hasLaunchedActivityFromFullScreenVideo"];
      }
    ) => {
      state.hasLaunchedActivityFromFullScreenVideo = payload;
    },
    setIsLaunchActivitySideBarOpen: (
      state,
      {
        payload,
      }: {
        payload: SpaceNavigationState["isLaunchActivitySideBarOpen"];
      }
    ) => {
      state.isLaunchActivitySideBarOpen = payload;
    },
    triggerSnapshotTask: (state) => {
      state.snapshotTasks = [
        ...state.snapshotTasks,
        {
          id: Date.now(),
          status: "created",
        },
      ];
    },
    updateSnapshotTask: (state, { payload }: { payload: SnapshotTask }) => {
      state.snapshotTasks = state.snapshotTasks.map((snapshot) =>
        snapshot.id === payload.id ? payload : snapshot
      );
    },
    removeSnapshotTask: (state, { payload }: { payload: number }) => {
      state.snapshotTasks = state.snapshotTasks.filter(
        (snapshot) => snapshot.id !== payload
      );
    },
    setShowVideoBlurLoading: (state, value) => {
      state.showVideoBlurLoading = value.payload;
    },
  },
});

export const {
  resetNavigation,
  resetExceptMeetingID,
  setMeetingID,
  setCurrentPage,
  setCurrentResourceId,
  setCurrentRoomItemId,
  setBrowserSandboxUrl,
  setBrowserSandboxInitialize,
  setWhiteboardBackground,
  setCustomColors,
  setCustomColor,
  setRoomItems,
  setThumbnailSrcs,
  setBackgroundSize,
  setBackgroundId,
  setShowRoomCustomizationActivityModal,
  setShowSourceModal,
  setShowInviteModal,
  setShowSettingsModal,
  setShowTimeoutWarningModal,
  setShowOpenRoomModal,
  setShareRoomModalRoomId,
  setDeleteRoomModalRoom,
  setDeleteSharedRoomModalSharedRoom,
  setSharedRoomFolderModalFolderId,
  setIsFullScreenVideo,
  setHasLaunchedActivityFromFullScreenVideo,
  setIsLaunchActivitySideBarOpen,
  triggerSnapshotTask,
  updateSnapshotTask,
  removeSnapshotTask,
  setShowVideoBlurLoading,
} = spaceNavigationSlice.actions;

export const selectMeetingID = (state: RootState) => {
  return state.spaceNavigation.meetingID;
};
export const selectCurrentPage = (state: RootState) =>
  state.spaceNavigation.currentPage;
export const selectCurrentResourceId = (state: RootState) =>
  state.spaceNavigation.currentResourceId;
export const selectCurrentRoomItemId = (state: RootState) =>
  state.spaceNavigation.currentRoomItemId;
export const selectBrowserSandboxUrl = (state: RootState) =>
  state.spaceNavigation.browserSandboxUrl;
export const selectBrowserSandboxInitialize = (state: RootState) =>
  state.spaceNavigation.browserSandboxInitialize;
export const selectWhiteboardBackground = (state: RootState) =>
  state.spaceNavigation.whiteboardBackground;
export const selectCustomColors = (state: RootState) =>
  state.spaceNavigation.customColors;
export const selectRoomItems = (state: RootState) =>
  state.spaceNavigation.roomItems;
export const selectThumbnailSrcs = (state: RootState) =>
  state.spaceNavigation.thumbnailSrcs;
export const selectBackgroundSize = (state: RootState) =>
  state.spaceNavigation.backgroundSize;
export const selectBackgroundId = (state: RootState) =>
  state.spaceNavigation.backgroundId;
export const selectShowRoomCustomizationActivityModal = (state: RootState) =>
  state.spaceNavigation.showRoomCustomizationActivityModal;
export const selectShowSourceModal = (state: RootState) =>
  state.spaceNavigation.showSourceModal;
export const selectShowInviteModal = (state: RootState) =>
  state.spaceNavigation.showInviteModal;
export const selectShowSettingsModal = (state: RootState) =>
  state.spaceNavigation.showSettingsModal;
export const selectShowTimeoutWarningModal = (state: RootState) =>
  state.spaceNavigation.showTimeoutWarningModal;
export const selectShowOpenRoomModal = (state: RootState) =>
  state.spaceNavigation.showOpenRoomModal;
export const selectShareRoomModalRoomId = (state: RootState) =>
  state.spaceNavigation.shareRoomModalRoomId;
export const selectDeleteRoomModalRoom = (state: RootState) =>
  state.spaceNavigation.deleteRoomModalRoom;
export const selectDeleteSharedRoomModalSharedRoom = (state: RootState) =>
  state.spaceNavigation.deleteSharedRoomModalSharedRoom;
export const selectSharedRoomFolderModalFolderId = (state: RootState) =>
  state.spaceNavigation.sharedRoomFolderModalFolderId;
export const selectIsFullScreenVideo = (state: RootState) =>
  state.spaceNavigation.isFullScreenVideo;
export const selectHasLaunchedActivityFromFullScreenVideo = (
  state: RootState
) => state.spaceNavigation.hasLaunchedActivityFromFullScreenVideo;
export const selectIsLaunchActivitySideBarOpen = (state: RootState) =>
  state.spaceNavigation.isLaunchActivitySideBarOpen;
export const selectSnapshotTasks = (state: RootState) =>
  state.spaceNavigation.snapshotTasks;
export const selectShowVideoBlurLoading = (state: RootState) =>
  state.spaceNavigation.showVideoBlurLoading;

export default spaceNavigationSlice.reducer;

import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import { fabric } from "utils/fabricUtils";
import { fabricTypes } from "utils/fabric-impl";
import styles from "./SpaceRoom.module.css";
import { HIT_SLOP } from "pages/Space/subpages/Whiteboard/Whiteboard";
import { useDispatch, useSelector } from "react-redux";
import {
  selectBackgroundId,
  selectRoomItems,
  selectShowRoomCustomizationActivityModal,
  selectThumbnailSrcs,
  setBackgroundId,
  setBackgroundSize,
  setRoomItems,
  setThumbnailSrcs,
} from "redux/spaceNavigationRedux";
import clsx from "clsx";
import { selectCanControl } from "redux/settingsRedux";
import {
  selectEncodedAuthToken,
  selectEncodedClientToken,
  selectUserRole,
  UserRole,
} from "redux/userRedux";
import { useUpdateItemLocationMutation } from "generated/graphql";
import { getNextZ } from "utils/resourceUtils";
import { useRemoteMouseOnCanvas } from "../Whiteboard/useRemoteMouseOnCanvas";
import LoadingAnimation from "components/LoadingAnimation/LoadingAnimation";
import ConnectionError from "../ConnectionError/ConnectionError";
import { logUnexpectedError } from "utils/errorUtils";
import { calculateFitDimensions } from "utils/sizingUtils";
import {
  defaultShadow,
  loadImage,
  maybeSetHoverShadow,
} from "./utils/drawingUtils";
import { useStateWithRef } from "hooks/useStateWithRef";
import { clearFabricCanvas, clearHTMLCanvas } from "utils/canvasUtils";
import { getBackgroundImage } from "./utils/backgroundUtils";
import RoomCustomizationActivityModal from "./components/RoomCustomizationActivityModal/RoomCustomizationActivityModal";
import {
  selectEditItemId,
  selectEditItemMoving,
  selectIsActivityBankOpen,
  selectNewlyAddedRoomitemId,
  setEditItemId,
  setEditItemMoving,
  setIsActivityBankOpen,
  setNewlyAddedRoomitemId,
  setIsProviderEditing,
} from "redux/editRoomNavigationRedux";
import {
  addOrUpdateItem,
  deleteOverlayIfPresent,
  fixCanvasObjectsSortingOrder,
  getOrCreateOverlay,
  setShadow,
} from "./utils/itemDrawingUtils";
import {
  scrubResourceData,
  useLogRoomItemEvent,
  useTrackEvent,
} from "utils/metricsUtils";
import { selectClientFileOpen } from "redux/clientManagementRedux";
import { useRoomItemOverlays } from "pages/Space/subpages/SpaceRoom/hooks/useRoomItemOverlays";
import { RoomItemOverlay } from "./components/RoomItemOverlay/RoomItemOverlay";
import { getFileUrls, sanitizeFileKeys } from "utils/fileUtils";
import { useBoxRef } from "hooks/useBoxRef";
import DisabledOverlay from "pages/Space/components/DisabledOverlay/DisabledOverlay";
import ExitEditingTooltip from "./components/ExitEditingTooltip/ExitEditingTooltip";
import { useExitEditingFloating } from "./components/ExitEditingTooltip/useExitEditingFloating";
import { useLaunchActivity } from "./hooks/useLaunchActivity";
import { useGetRoomQuerySharedHook } from "./hooks/useGetRoomQuerySharedHook";
import {
  useRoomEditingCloseActivityBankAnalytics,
  useRoomEditingUnselectItemAnalytics,
} from "./hooks/useRoomEditingAnalytics";
import { useTeleoEvent } from "pages/Space/components/ConnectionsContext/teleoPeerEventUtils";

type SpaceRoomProps = {};

type CanvasMousedownEvent = {
  x: number;
  y: number;
  elementId: string;
  itemLeft?: number;
  itemTop?: number;
};

const ROOM_ITEM_DRAG_THRESHOLD = 8;
const UNSCALED_CANVAS_WIDTH = 1500;
const UNSCALED_CANVAS_HEIGHT = 760;

const MAX_LABEL_WIDTH = 400;
const MAX_LABEL_HEIGHT = 70;

const PERCENT_FROM_LEFT_TO_EXTEND_HITBOX = 0.15;

const MAX_LABEL_WIDTH_X_CANVAS_WIDTH = MAX_LABEL_WIDTH * UNSCALED_CANVAS_WIDTH;
const MAX_LABEL_HEIGHT_X_CANVAS_HEIGHT =
  MAX_LABEL_HEIGHT * UNSCALED_CANVAS_HEIGHT;

const LONG_PRESS_THRESHOLD = 250;
const ROOM_ITEM_HOVER_FADE_OUT_NO_DELAY = 0;
const ROOM_ITEM_HOVER_FADE_OUT_LONG_DELAY = 500;
const ROOM_ITEM_HOVER_FADE_OUT_SHORT_DELAY = 250;

const SpaceRoom = ({}: SpaceRoomProps) => {
  const canvasSectionRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<fabricTypes.Canvas>();
  const [canvasIsInitialized, setCanvasIsInitialized] = useState(false);
  const remoteCursorCanvasRef = useRef<fabricTypes.Canvas>(); // for the remote cursor, so it's always in front and can't be deleted, etc
  const backgroundImageSizeRef = useRef<{ width: number; height: number }>();
  const clientCanControl = useSelector(selectCanControl);
  const userRole = useSelector(selectUserRole);
  const isProvider = userRole === UserRole.THERAPIST;
  const userCanControl = isProvider || clientCanControl;
  const showRoomCustomizationActivityModal = useSelector(
    selectShowRoomCustomizationActivityModal
  );
  const [isBackgroundLoaded, setIsBackgroundLoaded] = useState(false);
  const editItemId = useSelector(selectEditItemId);
  const editItemIdRef = useBoxRef(editItemId);
  const { trackUnselectItem } = useRoomEditingUnselectItemAnalytics();
  const editItemMoving = useSelector(selectEditItemMoving);
  const roomItems = useSelector(selectRoomItems);
  const roomItemsRef = useRef(roomItems); // for callback
  const encodedAuthToken = useSelector(selectEncodedAuthToken);
  const encodedClientToken = useSelector(selectEncodedClientToken);
  const encodedToken = encodedAuthToken || encodedClientToken;
  const [hoverItemId, setHoverItemId, hoverItemIdRef] = useStateWithRef<
    string | undefined
  >(undefined);
  const hoverTimeout = useRef<NodeJS.Timeout | undefined>(undefined);
  const lastHoveredItemIdRef = useRef<string | undefined>(undefined);
  const lastHoveredTimestampRef = useRef<number>(0);
  const longPressTimeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const longPressHappenedRef = useRef<boolean>(false);
  const [longPressingItemId, setLongPressingItemId, longPressingItemIdRef] =
    useStateWithRef<string | undefined>(undefined);
  const [loadingPreImages, setLoadingPreImages] = useState(true);
  const [loadingImages, setLoadingImages] = useState<boolean[]>();
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const [updateItemLocationMutation] = useUpdateItemLocationMutation();
  const [zoomLevelState, setZoomLevelState, zoomLevelStateRef] =
    useStateWithRef(1);
  const rawCanvasRef = useRef<HTMLCanvasElement>(null);
  const rawRemoteCursorCanvasRef = useRef<HTMLCanvasElement>(null);
  const logRoomItemEvent = useLogRoomItemEvent();
  const logRoomItemEventRef = useRef(logRoomItemEvent); // for mouse event callback
  useEffect(() => {
    logRoomItemEventRef.current = logRoomItemEvent;
  }, [logRoomItemEvent]);
  const { trackEvent } = useTrackEvent();
  const launchActivity = useLaunchActivity();
  const mouseDownEvent = useRef<CanvasMousedownEvent | undefined>(undefined);
  const isActivityBankOpen = useSelector(selectIsActivityBankOpen);
  const isActivityBankOpenRef = useBoxRef(isActivityBankOpen);
  const { trackCloseActivityBank } = useRoomEditingCloseActivityBankAnalytics();
  const isClientFileOpen = useSelector(selectClientFileOpen);
  const {
    fileItemIdRef,
    fileActivityStyle,
    websiteItemIdRef,
    websiteActivityStyle,
    hasAlbumItem,
    albumActivityStyle,
  } = useRoomItemOverlays(backgroundImageSizeRef);
  const thumbnailSrcs = useSelector(selectThumbnailSrcs);
  const backgroundId = useSelector(selectBackgroundId);
  const backgroundIdRef = useBoxRef(backgroundId);

  const newlyAddedRoomitemId = useSelector(selectNewlyAddedRoomitemId);
  const newlyAddedItemIdRef = useBoxRef(newlyAddedRoomitemId);

  const canMoveRoomItems = isProvider;

  useEffect(() => {
    // Clear canvas contents when unmounting to work around Safari canvas caching issues
    return () => {
      for (const canvas of [canvasRef.current, remoteCursorCanvasRef.current]) {
        clearFabricCanvas(canvas);
      }
      for (const rawCanvas of [
        rawCanvasRef.current,
        rawRemoteCursorCanvasRef.current,
      ]) {
        clearHTMLCanvas(rawCanvas);
      }
    };
  }, []);

  const { data, loadingRoomItemsQuery, refetchGetRoomQuery } =
    useGetRoomQuerySharedHook();

  const firstLoadingRoomItemsQuery = !data && loadingRoomItemsQuery;

  const emitRoomItemsChanged = useTeleoEvent("room-items-changed", () => {
    refetchGetRoomQuery();
  });

  const emitIsEditing = useTeleoEvent("is-editing", (payload) => {
    dispatch(setIsProviderEditing(payload.isEditing));
  });

  // Effect to fetch room items thumbnails and background image
  // after the room data query is available, after the canvas is initialized
  useEffect(() => {
    if (
      !canvasIsInitialized ||
      firstLoadingRoomItemsQuery ||
      !data ||
      data.meeting.length === 0 ||
      !data.meeting[0].provider?.current_room
    ) {
      return;
    }
    try {
      const room = data.meeting[0].provider.current_room;
      const newBackgroundId = room.background_id;
      if (newBackgroundId !== backgroundIdRef.current) {
        setIsBackgroundLoaded(false);
        setBackground(newBackgroundId).catch(logUnexpectedError);
      }

      const roomItems = room.room_items || [];
      dispatch(setRoomItems(roomItems));

      if (isProvider) {
        const executeAsync = async () => {
          const thumbnailKeys = roomItems
            .map((roomItem) => roomItem.resource.thumbnail_file_key)
            .filter((key) => !!key);
          const keysToGet = sanitizeFileKeys(thumbnailKeys);

          if (keysToGet.length > 0) {
            const thumbnailImageUrls = await getFileUrls(keysToGet);
            dispatch(setThumbnailSrcs(thumbnailImageUrls));
          }
        };

        executeAsync().catch(logUnexpectedError);
      }
    } catch (e) {
      logUnexpectedError(e);
      setError(true);
    }
  }, [data, firstLoadingRoomItemsQuery, canvasIsInitialized]);

  // Every dependency in this function needs to be a ref because it is used in
  // a fabric event listener.
  const updateRoomItemsOrder = () => {
    if (!canvasRef.current || !backgroundImageSizeRef.current) {
      return;
    }
    fixCanvasObjectsSortingOrder(canvasRef.current);

    const objects = canvasRef.current.getObjects();

    const editingItem = editItemIdRef.current
      ? objects.find((object) => object.itemId === editItemIdRef.current)
      : undefined;

    if (editItemIdRef.current) {
      const overlay = getOrCreateOverlay(
        canvasRef.current,
        backgroundImageSizeRef.current
      );

      overlay.bringToFront();

      if (editingItem) {
        editingItem.bringToFront();
      }
    } else {
      deleteOverlayIfPresent(canvasRef.current);
    }
  };

  // Effect that updates the room items on the canvas after first initialization
  useEffect(() => {
    if (!roomItems || !isBackgroundLoaded || !encodedToken) {
      return;
    }

    try {
      setLoadingPreImages(true);
      setLoadingImages(Array(roomItems.length).fill(false));

      roomItemsRef.current = roomItems;

      // Delete any items no longer included
      const currentObjects = canvasRef.current?.getObjects() || [];
      const itemIdSet = new Set(roomItems.map((item) => item.id));
      for (const object of currentObjects) {
        if (!itemIdSet.has(object.itemId) && !object.isOverlay) {
          canvasRef.current?.remove(object);
        }
      }

      // Add or update items
      roomItems.forEach((item, index) => {
        addOrUpdateItem(
          item,
          index,
          backgroundImageSizeRef,
          canvasRef,
          editItemIdRef,
          newlyAddedRoomitemId,
          canMoveRoomItems,
          setError,
          setLoadingImages,
          dispatch
        ).catch((e) => {
          setError(true);
          logUnexpectedError(e);
        });
      });

      updateRoomItemsOrder();
    } catch (e) {
      setError(true);
      logUnexpectedError(e);
      setLoadingImages(Array(roomItems.length).fill(false));
    } finally {
      setLoadingPreImages(false);
    }
  }, [roomItems, isBackgroundLoaded, encodedToken]);

  const { maybeEmitCursorMove, maybeEmitCursorOut } = useRemoteMouseOnCanvas(
    remoteCursorCanvasRef,
    zoomLevelState,
    zoomLevelStateRef
  );

  const baseOnMouseOut = () => {
    maybeEmitCursorOut();
  };

  const calculateCanvasDimensions = () => {
    const parentHeight = canvasSectionRef.current?.clientHeight || 0;
    const parentWidth = canvasSectionRef.current?.clientWidth || 0;
    const rawWidth = backgroundImageSizeRef.current?.width || 0;
    const rawHeight = backgroundImageSizeRef.current?.height || 0;
    const { fitHeight, fitWidth, ratio } = calculateFitDimensions(
      parentHeight,
      parentWidth,
      rawHeight,
      rawWidth,
      null
    );
    setZoomLevelState(ratio);
    return {
      canvasHeight: fitHeight,
      canvasWidth: fitWidth,
      zoomLevel: ratio,
    };
  };

  function updateSize() {
    const { canvasHeight, canvasWidth, zoomLevel } =
      calculateCanvasDimensions();
    // Avoid setting to 0 because this causes the canvas to be blank when it is made bigger
    // again later.
    if (!canvasHeight || !canvasWidth || !zoomLevel) {
      return;
    }
    for (const canvas of [canvasRef.current, remoteCursorCanvasRef.current]) {
      if (!canvas) {
        continue;
      }
      canvas.setHeight(canvasHeight);
      canvas.setWidth(canvasWidth);
      canvas.setZoom(zoomLevel);
    }
  }

  useLayoutEffect(() => {
    window.addEventListener("resize", updateSize);
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  // Effect to update shadows and room item styles when the editItemId changes
  useEffect(() => {
    if (!canvasRef.current) {
      return;
    }

    const isEditing = !!editItemId;
    // Set shadows and update the cursor for the poster
    for (const object of canvasRef.current.getObjects() || []) {
      setShadow(object, editItemIdRef, newlyAddedRoomitemId);
      if (object.isPoster) {
        object.set({
          hoverCursor: isEditing || isActivityBankOpen ? "pointer" : "default",
        });
      }
    }

    updateRoomItemsOrder();

    canvasRef.current.defaultCursor = isEditing ? "pointer" : "default";

    canvasRef.current.renderAll();
  }, [editItemId, isActivityBankOpen, newlyAddedRoomitemId]);

  const setBackground = async (backgroundId: string) => {
    dispatch(setBackgroundId(backgroundId));
    const backgroundImageSrc = getBackgroundImage(backgroundId);
    const backgroundImage = await loadImage(backgroundImageSrc);
    backgroundImage.set({ opacity: 0.7 });
    const backgroundSize = {
      height: backgroundImage.height || 0,
      width: backgroundImage.width || 0,
    };
    dispatch(setBackgroundSize(backgroundSize));
    backgroundImageSizeRef.current = backgroundSize;
    updateSize();
    canvasRef.current?.setBackgroundImage(backgroundImage, () => {
      setIsBackgroundLoaded(true);
    });
  };

  const applyHoverEffect = (itemId: string) => {
    if (!canvasRef.current) {
      return;
    }
    const fabricObject = canvasRef.current
      .getObjects()
      .find((obj) => obj.itemId === itemId);

    if (!fabricObject) {
      return;
    }

    const isPoster = fabricObject.isPoster;
    const isEditing = !!editItemIdRef.current || isActivityBankOpenRef.current;
    setHoverItemId(itemId);
    maybeSetHoverShadow(
      fabricObject,
      itemId,
      isPoster,
      editItemIdRef.current,
      isEditing
    );
    if (isProvider && !!editItemIdRef.current) {
      updateRoomItemsOrder();
      fabricObject.bringToFront();
    }
    canvasRef.current.renderAll();
  };

  const handleMouseOut = (
    itemId: string,
    isPoster: boolean,
    fabricRoomItem: fabricTypes.Object,
    timeout: number
  ) => {
    if (itemId !== hoverItemIdRef.current) {
      lastHoveredItemIdRef.current = undefined;
      return;
    }

    // Clear any existing timeout
    if (hoverTimeout.current) {
      clearTimeout(hoverTimeout.current);
    }

    const executeHoverOut = () => {
      hoverTimeout.current = undefined;
      if (
        lastHoveredItemIdRef.current &&
        lastHoveredItemIdRef.current !== itemId
      ) {
        applyHoverEffect(lastHoveredItemIdRef.current);
      }

      // If we didn't defer the mouse out event, we can handle it now
      if (itemId === hoverItemIdRef.current) {
        setHoverItemId(undefined);
        lastHoveredItemIdRef.current = undefined;
        lastHoveredTimestampRef.current = 0;
        updateRoomItemsOrder();
      }

      if (itemId === editItemIdRef.current) {
        return;
      }
      // Don't show a shadow on the poster unless editing the room
      const isEditing =
        !!editItemIdRef.current || isActivityBankOpenRef.current;
      if (isPoster && !isEditing) {
        return;
      }
      const scale = fabricRoomItem.scaleX || 1;
      const shadow = defaultShadow(scale);
      fabricRoomItem.set({ shadow });
      canvasRef.current?.renderAll();
    };

    // Set a timeout to remove the hover effect after 100ms
    hoverTimeout.current = setTimeout(executeHoverOut, timeout);
  };

  /**
   * Handles the mouse move event when hovering out of an item.
   *
   * @param event - The mouse event from the Fabric.js canvas.
   *
   * This function checks if the mouse pointer has moved out of the hover area of an item
   * and triggers the `handleMouseOut` function if necessary. It calculates the distances
   * from the pointer to the item's edges and determines if the pointer is still within
   * the hoverable label area or not.
   */
  const handleMouseMoveHoverOut = (event: fabricTypes.IEvent<MouseEvent>) => {
    // Return early if not a provider, the itemId is the same as the current hoverItemId,
    // or if hoverTimeout or canvasRef are not set (null or undefined).
    const itemId = event.target?.itemId;
    if (
      !isProvider ||
      itemId === hoverItemIdRef.current ||
      !hoverTimeout.current ||
      !canvasRef.current
    ) {
      return;
    }

    // Get the pointer position from the canvas reference using the event
    const pointer = canvasRef.current?.getPointer(event.e);
    if (!pointer) {
      return;
    }

    // Find the fabric object on the canvas that matches the current hover item ID
    const fabricObject = canvasRef.current
      .getObjects()
      .find((obj) => obj.itemId === hoverItemIdRef.current);
    if (!fabricObject) {
      return;
    }

    // Calculates the scaled width and height of the item.
    const scaledItemWidth = fabricObject.getScaledWidth() ?? 0;
    const scaledItemHeight = fabricObject.getScaledHeight() ?? 0;

    // Determines the item's center, right, and bottom positions.
    const itemCenterX = (fabricObject.left ?? 0) + scaledItemWidth / 2;
    const itemRightX = (fabricObject.left ?? 0) + scaledItemWidth;
    const itemBottomY = (fabricObject.top ?? 0) + scaledItemHeight;

    // Calculates the distances from the pointer to the item's center, right, and bottom edges.
    const distanceXFromCenter = pointer.x - itemCenterX;
    const distanceXFromRight = pointer.x - itemRightX;
    const distanceYFromBottom = pointer.y - itemBottomY;

    const scaledCanvasWidth = canvasRef.current.width || 1;
    const scaledCanvasHeight = canvasRef.current.height || 1;

    // Calculates the maximum label width and height in canvas units.
    const maxLabelWidthInCanvasUnits =
      MAX_LABEL_WIDTH_X_CANVAS_WIDTH / scaledCanvasWidth;
    const maxLabelHeightInCanvasUnits =
      MAX_LABEL_HEIGHT_X_CANVAS_HEIGHT / scaledCanvasHeight;

    // Determines if the item's right edge is close to the left side of the canvas.
    // This is important for thin items that are close to the left side of the canvas.
    const isBottomRightCloseToLeftSide =
      itemRightX <= scaledCanvasWidth * PERCENT_FROM_LEFT_TO_EXTEND_HITBOX;

    // Calculates the label hitbox width.
    const labelHitboxWidth =
      maxLabelWidthInCanvasUnits / (isBottomRightCloseToLeftSide ? 1 : 2);

    // Checks if the pointer is hovering over the label or to the right of the item.
    const isHoveringOverLabel =
      Math.abs(distanceXFromCenter) <= labelHitboxWidth &&
      distanceYFromBottom >= 0 &&
      distanceYFromBottom <= maxLabelHeightInCanvasUnits;

    const canLabelBeWiderThanItem =
      maxLabelWidthInCanvasUnits > scaledItemWidth;

    const hitBoxPaddingWidth = scaledCanvasWidth * 0.01;
    const hitBoxPaddingHeight = scaledCanvasHeight * 0.04;

    const isHoveringOverItem =
      pointer.x >= fabricObject.left! - hitBoxPaddingWidth &&
      pointer.x <= itemRightX + hitBoxPaddingWidth &&
      pointer.y <= itemBottomY + hitBoxPaddingHeight &&
      pointer.y >= fabricObject.top! - hitBoxPaddingHeight;

    const isHoveringRightOfItem =
      distanceXFromRight >= 0 &&
      distanceXFromCenter <= labelHitboxWidth &&
      pointer.y <= itemBottomY + hitBoxPaddingHeight &&
      pointer.y >= fabricObject.top! - hitBoxPaddingHeight;

    // Use a shorter delay when hovering over an item
    const roomItemHoverFadeOutDelay = !!itemId
      ? ROOM_ITEM_HOVER_FADE_OUT_SHORT_DELAY
      : ROOM_ITEM_HOVER_FADE_OUT_LONG_DELAY;

    // If the pointer is no longer hovering over the label or item, triggers the `handleMouseOut` function.
    const newTimeout =
      isHoveringOverLabel ||
      isHoveringOverItem ||
      (canLabelBeWiderThanItem && isHoveringRightOfItem)
        ? roomItemHoverFadeOutDelay
        : ROOM_ITEM_HOVER_FADE_OUT_NO_DELAY;

    handleMouseOut(
      hoverItemIdRef.current!,
      !!fabricObject.isPoster,
      fabricObject,
      newTimeout
    );
  };

  const initializeCanvas = () => {
    try {
      if (!canvasRef.current) {
        // Fabric will create a wrapper around the html canvas element with the id 'canv'
        canvasRef.current = new fabric.Canvas("room-canv", {
          backgroundColor: "white",
          selection: false,
          targetFindTolerance: HIT_SLOP * 2,
          preserveObjectStacking: true,
        });
        if (backgroundIdRef.current) {
          setBackground(backgroundIdRef.current).catch(logUnexpectedError);
        }

        const longpressHandler = () => {
          if (!mouseDownEvent.current) {
            return;
          }
          const { elementId } = mouseDownEvent.current;

          longPressTimeoutRef.current = undefined;
          longPressHappenedRef.current = true;
          setLongPressingItemId(elementId);
        };

        const moveItemHandler = async (
          roomItem: fabricTypes.Object,
          originalItemLeft: number | undefined,
          originalItemTop: number | undefined
        ) => {
          const errorMessage =
            "Sorry, there was a problem moving the item. Please try again later.";
          if (!backgroundImageSizeRef.current) {
            logUnexpectedError(
              "No backgroundImageSizeRef.current when dragging object"
            );
            alert(errorMessage);
            return;
          }
          const newTop = roomItem.top;
          const newLeft = roomItem.left;
          const newX = (newLeft || 0) / backgroundImageSizeRef.current.width;
          const newY = (newTop || 0) / backgroundImageSizeRef.current.height;

          const newZ = getNextZ(roomItemsRef.current || []);
          const { errors } = await updateItemLocationMutation({
            variables: {
              itemId: roomItem.itemId,
              rx: newX,
              ry: newY,
              z: newZ,
            },
          });
          dispatch(setEditItemMoving(undefined));
          if (errors) {
            logUnexpectedError(errors);
            alert(errorMessage);
          } else {
            const item = roomItemsRef.current?.find(
              (item) => item.id === roomItem.itemId
            );
            if (item) {
              logRoomItemEventRef
                .current({
                  action: "MOVE",
                  iconId: item.icon_id,
                  resourceId: item.resource.id,
                })
                .catch(logUnexpectedError);

              const previousX = originalItemLeft
                ? originalItemLeft / backgroundImageSizeRef.current.width
                : undefined;
              const previousY = originalItemTop
                ? originalItemTop / backgroundImageSizeRef.current.height
                : undefined;

              const scrubbedResource = scrubResourceData(item.resource);

              trackEvent("Room item moved", {
                "Old x coordinate": previousX,
                "Old y coordinate": previousY,
                "New x coordinate": newX,
                "New y coordinate": newY,
                "Item icon ID": item.icon_id,
                "Resource ID": scrubbedResource.id,
                "Resource name": scrubbedResource.name,
                "Is activity bank open": isActivityBankOpenRef.current
                  ? "open"
                  : "closed",
              });
            }
          }
        };

        canvasRef.current?.on("mouse:over", async (event) => {
          const itemId = event.target?.itemId;

          if (lastHoveredItemIdRef.current !== itemId) {
            lastHoveredItemIdRef.current = itemId;
            lastHoveredTimestampRef.current = Date.now();
          }

          if (event.target && itemId) {
            dispatch(setNewlyAddedRoomitemId(undefined));
            if (hoverTimeout.current && itemId === hoverItemIdRef.current) {
              clearTimeout(hoverTimeout.current);
              hoverTimeout.current = undefined;
              return;
            }
            if (hoverTimeout.current) {
              return;
            }
            applyHoverEffect(itemId);
          }
        });

        canvasRef.current?.on(
          "mouse:out",
          async (event: fabricTypes.IEvent<MouseEvent>) => {
            const fabricEventTarget = event.target;
            const itemId = fabricEventTarget?.itemId;
            const isPoster = fabricEventTarget?.isPoster;

            if (fabricEventTarget && itemId) {
              handleMouseOut(
                itemId,
                !!isPoster,
                fabricEventTarget,
                ROOM_ITEM_HOVER_FADE_OUT_NO_DELAY
              );
            }
          }
        );
        canvasRef.current?.on("mouse:down", (event) => {
          // Fabric.js can trigger a mouse:down with a TouchEvent param instead of a MouseEvent.
          const clickOrTouch: MouseEvent | Touch | undefined =
            event.e instanceof MouseEvent
              ? event.e
              : (event.e as unknown as TouchEvent).touches?.[0];

          if (newlyAddedItemIdRef.current) {
            dispatch(setNewlyAddedRoomitemId(undefined));
          }

          if (
            !clickOrTouch ||
            !clickOrTouch.pageX ||
            !clickOrTouch.pageY ||
            !event.target?.itemId
          ) {
            return;
          }
          mouseDownEvent.current = {
            x: clickOrTouch.pageX,
            y: clickOrTouch.pageY,
            elementId: event.target.itemId,
            itemLeft: event.target.left,
            itemTop: event.target.top,
          };

          longPressHappenedRef.current = false;
          if (longPressTimeoutRef.current) {
            clearTimeout(longPressTimeoutRef.current);
          }

          if (event.e.type === "touchstart") {
            longPressTimeoutRef.current = setTimeout(() => {
              if (mouseDownEvent.current) {
                longpressHandler();
              }
            }, LONG_PRESS_THRESHOLD);
          }
        });
        canvasRef.current?.on("mouse:move", (event) => {
          handleMouseMoveHoverOut(event);
          if (!mouseDownEvent.current || !canMoveRoomItems) {
            return;
          }

          // Fabric.js can trigger a mouse:move with a TouchEvent param instead of a MouseEvent.
          const clickOrTouch: MouseEvent | Touch | undefined =
            event.e instanceof MouseEvent
              ? event.e
              : (event.e as unknown as TouchEvent).touches?.[0];

          if (!clickOrTouch) {
            return;
          }

          const { x: downX, y: downY, elementId } = mouseDownEvent.current;

          const originalTargetObject = canvasRef.current
            ?.getObjects()
            .find((obj) => obj.itemId === elementId);
          if (!originalTargetObject) {
            return;
          }

          const distanceX = Math.abs(clickOrTouch.pageX - downX);
          const distanceY = Math.abs(clickOrTouch.pageY - downY);
          const totalDistance = Math.sqrt(distanceX ** 2 + distanceY ** 2);

          if (totalDistance > ROOM_ITEM_DRAG_THRESHOLD) {
            // When item position is updated, updateItemFields will lock it back
            originalTargetObject.set({
              lockMovementX: false,
              lockMovementY: false,
            });
            originalTargetObject.bringToFront();

            if (longPressTimeoutRef.current) {
              clearTimeout(longPressTimeoutRef.current);
              longPressTimeoutRef.current = undefined;
            }
          }
        });
        canvasRef.current?.on("mouse:up", async (event) => {
          // Detect if drag or click
          // If user can't move items, always treat as click
          // If object was unlocked due to movement, treat as drag
          const itemIsUnlocked = event.target?.lockMovementX === false;
          const isClick =
            !longPressHappenedRef.current &&
            (!canMoveRoomItems || !itemIsUnlocked);

          const {
            elementId: mouseDownItemId,
            itemLeft: originalItemLeft,
            itemTop: originalItemTop,
          } = mouseDownEvent.current ?? {};
          mouseDownEvent.current = undefined;

          if (longPressTimeoutRef.current) {
            clearTimeout(longPressTimeoutRef.current);
            longPressTimeoutRef.current = undefined;
          }

          const itemId = event.target?.itemId;
          const isPoster = event.target?.isPoster;

          const isEditing = !!editItemIdRef.current;
          const isCancellingCurrentEdit =
            !itemId || !mouseDownItemId || itemId !== mouseDownItemId;

          // if clicking on room background
          if (isCancellingCurrentEdit) {
            if (isEditing) {
              dispatch(setEditItemId(undefined));
              trackUnselectItem("Backdrop");
              dispatch(setEditItemMoving(undefined));
            } else if (longPressingItemIdRef.current) {
              setLongPressingItemId(undefined);
            } else {
              dispatch(setIsActivityBankOpen(false));
              trackCloseActivityBank("Backdrop");
            }
            return;
          }

          if (
            isClick &&
            (isEditing || (isPoster && isActivityBankOpenRef.current))
          ) {
            // de-selecting the current item for edit
            if (editItemIdRef.current === itemId) {
              dispatch(setEditItemId(undefined));
              trackUnselectItem("Room Item Click");
              dispatch(setEditItemMoving(undefined));
              return;
            }

            dispatch(setIsActivityBankOpen(true));
            dispatch(setEditItemId(itemId));
            setLongPressingItemId(undefined);
            return;
          }

          if (isClick) {
            const roomItem = roomItemsRef.current?.find(
              (item) => item.id === itemId
            );
            if (isProvider) {
              if (itemId === fileItemIdRef.current) {
                // @ts-ignore
                window.pendo.onGuideAdvanced(3);
              }
              if (itemId === websiteItemIdRef.current) {
                // @ts-ignore
                window.pendo.onGuideAdvanced(5);
              }
            }
            launchActivity(roomItem, false);

            if (roomItem) {
              const isPrivateResource = !!roomItem.resource.owner_id;

              trackEvent("Room - Activity Launched", {
                ["resource id"]: roomItem.resource.id,
                ["resource name"]: isPrivateResource
                  ? "<SCRUBBED_PRIVATE_RESOURCE>"
                  : roomItem.resource.name,
              });
            }
          } else {
            // handle drag while outside of edit mode
            // if the item is locked, it means it was not moved
            if (!itemId || !event.target || !itemIsUnlocked) {
              return;
            }
            await moveItemHandler(
              event.target,
              originalItemLeft,
              originalItemTop
            );
            emitRoomItemsChanged({});
          }
        });
      }
      setCanvasIsInitialized(true);
      if (!remoteCursorCanvasRef.current) {
        remoteCursorCanvasRef.current = new fabric.Canvas(
          "room-remoteCursorCanv",
          {
            backgroundColor: undefined,
          }
        );
      }
    } catch (e) {
      logUnexpectedError(e);
      setError(true);
    }

    const mouseMoveListener = (event: MouseEvent) => {
      const hasActivityBankOrEditPosterOpen =
        isActivityBankOpenRef.current || !!editItemIdRef.current;
      if (hasActivityBankOrEditPosterOpen) {
        return;
      }
      const pointer = canvasRef.current?.getPointer(event);
      if (pointer) {
        maybeEmitCursorMove(pointer);
      }
    };

    window.addEventListener("mousemove", mouseMoveListener);
    return () => {
      window.removeEventListener("mousemove", mouseMoveListener);

      // When opening/closing activities, this component is unmounted.
      // We need to cancel room item moving when this happens.
      const { elementId, itemLeft, itemTop } = mouseDownEvent.current ?? {};
      mouseDownEvent.current = undefined;

      const movingObject = canvasRef.current
        ?.getObjects()
        .find((obj) => obj.itemId === elementId);

      movingObject?.set({
        lockMovementX: true,
        lockMovementY: true,
        left: itemLeft,
        top: itemTop,
      });

      canvasRef.current?.discardActiveObject();
      dispatch(setEditItemMoving(undefined));
    };
  };

  useEffect(initializeCanvas, []);

  useEffect(() => {
    updateSize();
    if (isProvider) {
      emitIsEditing({
        isEditing: isActivityBankOpen || !!editItemId,
      });
    }
  }, [isActivityBankOpen, isClientFileOpen, editItemId]);

  const noCurrentRoom =
    !firstLoadingRoomItemsQuery && !data?.meeting[0]?.provider?.current_room;
  const loadingRoomImages =
    (loadingPreImages || (loadingImages && loadingImages.includes(true))) &&
    !noCurrentRoom;

  const noRoomMessage = isProvider
    ? "No room is currently opened. Please open a room."
    : "No room is currently opened. Please wait for your provider to open a room.";

  const onRoomItemButtonsMouseEnter = (itemId: string) => {
    if (lastHoveredItemIdRef.current !== itemId) {
      lastHoveredItemIdRef.current = itemId;
      lastHoveredTimestampRef.current = Date.now();
    }
    if (hoverTimeout.current && itemId === hoverItemIdRef.current) {
      clearTimeout(hoverTimeout.current);
      hoverTimeout.current = undefined;
      return;
    }
  };

  const onRoomItemButtonsMouseLeave = (itemId: string) => {
    const fabricRoomItem = canvasRef.current
      ?.getObjects()
      .find((obj) => obj.itemId === itemId);

    if (!fabricRoomItem) {
      return;
    }
    const isPoster = !!fabricRoomItem.isPoster;
    handleMouseOut(
      itemId,
      isPoster,
      fabricRoomItem,
      ROOM_ITEM_HOVER_FADE_OUT_NO_DELAY
    );
  };

  const exitEditingFloating = useExitEditingFloating();

  return (
    <div className={styles.space} data-testid="space-room">
      <div
        className={clsx(styles.canvasAndSideBarContainer, {
          [styles.noControlCanvasSection]: !userCanControl,
        })}
      >
        <div className={styles.canvasAndTopBarContainer}>
          <div
            className={clsx(styles.canvasSection, {
              [styles.noControlCanvasSection]: !userCanControl,
            })}
            ref={canvasSectionRef}
          >
            <div
              data-testid="room-canvas-container"
              className={clsx(styles.canvasContainer, {
                [styles.noControlCanvasContainer]: !userCanControl,
              })}
              onPointerLeave={baseOnMouseOut}
              ref={exitEditingFloating.refs.setReference}
              {...exitEditingFloating.getReferenceProps()}
            >
              <canvas id="room-canv" ref={rawCanvasRef} />
              {isBackgroundLoaded &&
                roomItems?.map((item) => (
                  <RoomItemOverlay
                    key={item.id}
                    roomItem={item}
                    thumbnailSrc={
                      item.resource.thumbnail_file_key
                        ? thumbnailSrcs[item.resource.thumbnail_file_key]
                        : undefined
                    }
                    backgroundImageSizeRef={backgroundImageSizeRef}
                    canvasRef={canvasRef}
                    editItemId={editItemId}
                    editItemMoving={editItemMoving}
                    isHovering={hoverItemId === item.id}
                    isLongPressing={longPressingItemId === item.id}
                    onRoomItemButtonsMouseEnter={onRoomItemButtonsMouseEnter}
                    onRoomItemButtonsMouseLeave={onRoomItemButtonsMouseLeave}
                  />
                ))}
              {showRoomCustomizationActivityModal ? (
                <RoomCustomizationActivityModal
                  roomWidth={canvasRef.current?.width}
                />
              ) : null}
              <div className={styles.remoteTmpCanvas}>
                <canvas
                  id="room-remoteCursorCanv"
                  ref={rawRemoteCursorCanvasRef}
                />
              </div>
              {isBackgroundLoaded ? (
                <div
                  id={"feelingsChartOverlay"}
                  className={styles.feelingsChartOverlayForTour}
                  style={fileActivityStyle}
                />
              ) : null}
              {isBackgroundLoaded ? (
                <div
                  id={"pencilCupOverlay"}
                  className={styles.feelingsChartOverlayForTour}
                  style={websiteActivityStyle}
                />
              ) : null}
              {isBackgroundLoaded && hasAlbumItem ? (
                <div
                  id={"albumOverlay"}
                  className={styles.feelingsChartOverlayForTour}
                  style={albumActivityStyle}
                />
              ) : null}
            </div>
            {noCurrentRoom ? (
              <div className={styles.noRoomMessage}>{noRoomMessage}</div>
            ) : null}
            {loadingRoomImages ? (
              <div
                className={clsx(styles.loadingContainer, {
                  [styles.semitransparent]: isBackgroundLoaded,
                })}
              >
                {" "}
                <LoadingAnimation />{" "}
              </div>
            ) : null}
            {firstLoadingRoomItemsQuery ? (
              <div className={styles.loadingContainer}>
                {" "}
                <LoadingAnimation />{" "}
              </div>
            ) : null}
            {error ? (
              <ConnectionError
                errorMessage={
                  "There was an error loading the room. Please try again later."
                }
                loading={false}
                showInviteLink={false}
              />
            ) : null}
          </div>
        </div>
      </div>
      {userRole === UserRole.CLIENT && (
        <DisabledOverlay message="Hold tight! The room is being edited." />
      )}
      <ExitEditingTooltip
        exitEditingFloating={exitEditingFloating}
        hoverItemId={hoverItemId}
        lastHoveredItemId={lastHoveredItemIdRef.current}
      />
    </div>
  );
};

export default SpaceRoom;

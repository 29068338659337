import React, { useState } from "react";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useSelector } from "react-redux";
import { logUnexpectedError } from "utils/errorUtils";
import {
  selectEncodedAuthToken,
  selectEncodedClientToken,
  UserRole,
} from "redux/userRedux";
import { useIsTherapist } from "pages/Space/hooks/useIsTherapist";
import { io, Socket } from "socket.io-client";
import { useBoxRef } from "hooks/useBoxRef";
import { useGatewayConnectionCtx } from "./GatewayConnectionContext";
import { getLocalPeerId } from "./WebRTCContext";

export interface DesktopControlEvent {
  openUrl: (data: {
    url: string;
    roomItemId: string;
    resourceId: string;
  }) => void;
  refreshPage: ({}) => void;
  mouseMove: (data: { agent: UserRole; xp: number; yp: number }) => void;
  scroll: (data: { agent: UserRole; x: number; y: number }) => void;
  buttonDown: (data: {
    agent: UserRole;
    key: number;
    xp: number;
    yp: number;
  }) => void;
  keyDown: (data: { agent: UserRole; key: number }) => void;
  buttonUp: (data: {
    agent: UserRole;
    key: number;
    xp: number;
    yp: number;
  }) => void;
  keyUp: (data: { agent: UserRole; key: number }) => void;
}

export interface ClientToRoomEvents extends DesktopControlEvent {
  "end session": () => void;
  ping: (pingId: number) => void;
}

export type RoomToClientError = "INVALID";

export interface RoomToClientEvents {
  error: (errorCode: RoomToClientError) => void;
  "session ended": () => void;
  pong: (pingId: number) => void;
}

export type RoomSocketType = Socket<RoomToClientEvents, ClientToRoomEvents>;

type RoomConnectionContextProps = {
  roomSocket?: RoomSocketType;
};

type RoomConnectionContextProviderProps = {
  children: ReactNode;
};

const RoomConnectionContext = createContext<
  RoomConnectionContextProps | undefined
>(undefined);

const urlRe = /https?:\/\/[^\/]*/;

export const RoomConnectionProvider = ({
  children,
}: RoomConnectionContextProviderProps) => {
  const { roomContainer: roomContainerInfo } = useGatewayConnectionCtx();
  const containerInstance = roomContainerInfo?.containerInstance;
  const containerId = roomContainerInfo?.containerId;

  const encodedAuthToken = useSelector(selectEncodedAuthToken);
  const encodedClientToken = useSelector(selectEncodedClientToken);
  const encodedClientTokenRef = useBoxRef(encodedClientToken); // for callback
  const isTherapist = useIsTherapist();
  const roleString = isTherapist ? "therapist" : "client";

  const [roomSocket, setRoomSocket] = useState<RoomSocketType | undefined>(
    undefined
  );

  useEffect(() => {
    if (!containerInstance || !containerId) {
      return;
    }
    const urlHostMatches = containerInstance.match(urlRe);
    if (!urlHostMatches || urlHostMatches.length === 0) {
      logUnexpectedError("Container instance url malformed");
      return;
    }
    const urlHost = urlHostMatches[0];
    const urlPath = containerInstance.substring(urlHost.length);
    const encodedToken = encodedAuthToken || encodedClientTokenRef.current;

    const socket = io(urlHost, {
      transports: ["websocket", "polling"],
      path: `${urlPath}/room/${containerId}/socket.io`,
      closeOnBeforeunload: false, // keep the socket open to send the "bye" event onbeforeunload
      withCredentials: true,
      reconnectionDelayMax: 1500,
      timeout: 3000,
      // enable retries
      // ackTimeout: 5000,
      // retries: 2 * 60,
      // this is the initial auth object. This will be mutated and
      // the updated object is sent when attempting to reconnect
      auth: {
        peerId: getLocalPeerId(),
        token: encodedToken,
        role: roleString,
      },
    });

    setRoomSocket(socket);
    return () => {
      socket.disconnect();
    };
  }, [containerInstance, containerId]);

  const contextValue = useMemo(
    () => ({
      roomSocket,
    }),
    [roomSocket]
  );

  return (
    <RoomConnectionContext.Provider value={contextValue}>
      {children}
    </RoomConnectionContext.Provider>
  );
};

export const useRoomConnectionCtx = () => {
  const contextValue = useContext(RoomConnectionContext)!;
  return contextValue;
};

export const useRoomEventListener = <
  Ev extends keyof RoomToClientEvents,
  Listener extends RoomToClientEvents[Ev]
>(
  ev: Ev,
  listener: Listener
) => {
  const { roomSocket } = useRoomConnectionCtx();

  useEffect(() => {
    // @ts-ignore
    roomSocket?.on(ev, listener);
    return () => {
      // @ts-ignore
      roomSocket?.off(ev, listener);
    };
  }, [roomSocket, ev, listener]);
};

export const useEmitDesktopControlEvent = () => {
  const { roomSocket } = useRoomConnectionCtx();
  return <
    Ev extends keyof ClientToRoomEvents,
    Payload extends Parameters<ClientToRoomEvents[Ev]>
  >(
    ev: Ev,
    ...payload: Payload
  ) => {
    roomSocket?.emit(ev, ...payload);
  };
};

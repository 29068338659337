import React from "react";
import styles from "./ControlButton.module.css";
import clsx from "clsx";
import { fabricTypes } from "utils/fabric-impl";
import { COLORS } from "teleoConstants";

import deleteIcon from "assets/icons/eraser.svg";

import {
  ERASER_BRUSH_HEIGHT_MULTIPLIER,
  getEraserBrush,
  getPaintBrush,
} from "pages/Space/subpages/SpaceRoom/utils/drawingUtils";
import TextButton, { TextSize } from "./TextButton";
import { TOOLBAR_BUTTON_OUTLINE_MULTIPLIER } from "./WhiteboardControls";
import { MultiColorButton } from "./MultiColorButton";

export enum ControlButtonType {
  TEXT,
  FREE_DRAW,
  DELETE,
  DIVIDER,
}

export type BaseConfig = {
  type: ControlButtonType;
};

interface FreeDrawConfig extends BaseConfig {
  type: ControlButtonType.FREE_DRAW;
  color?: string;
  hasColorPicker?: boolean;
}

interface TextConfig extends BaseConfig {
  type: ControlButtonType.TEXT;
}

interface DeleteConfig extends BaseConfig {
  type: ControlButtonType.DELETE;
}

interface DividerConfig extends BaseConfig {
  type: ControlButtonType.DIVIDER;
}

export type ControlButtonConfig =
  | FreeDrawConfig
  | TextConfig
  | DeleteConfig
  | DividerConfig;

type ControlButtonProps = {
  id: string;
  config: ControlButtonConfig;
  isSelected: boolean;
  setIsSelected: () => void;
  canvasRef: React.MutableRefObject<fabricTypes.Canvas | undefined>;
  containerRef: React.MutableRefObject<HTMLDivElement | null>;
  focusedTextRef: React.MutableRefObject<fabricTypes.IText | undefined>;
  textSizeRef: React.MutableRefObject<TextSize>;
  emitBrushChange: (payload: { color: string }) => void;
  buttonHeight: number;
  visibleHeight: number;
  setCursorRadius: (radius: number) => void;
  setShowCursor: (show: boolean) => void;
};

const ControlButton = ({
  id,
  config,
  isSelected,
  setIsSelected,
  canvasRef,
  containerRef,
  focusedTextRef,
  textSizeRef,
  emitBrushChange,
  buttonHeight,
  visibleHeight,
  setCursorRadius,
  setShowCursor,
}: ControlButtonProps) => {
  const updateCanvasBrushColor = (color: string) => {
    if (canvasRef.current) {
      if (canvasRef.current.freeDrawingBrush?.color !== "rgb(0, 0, 0)") {
        canvasRef.current.freeDrawingBrush.color = color;
      } else {
        canvasRef.current.freeDrawingBrush = getPaintBrush(
          color,
          visibleHeight,
          canvasRef.current
        );
      }
      emitBrushChange({ color });
    }
  };

  const onClick = () => {
    if (!isSelected && canvasRef.current) {
      setIsSelected();

      if (config.type !== ControlButtonType.TEXT) {
        // Unfocus any text elements being edited
        if (focusedTextRef.current) {
          focusedTextRef.current.exitEditing();
          focusedTextRef.current = undefined;
        }
      }

      if (config.type === ControlButtonType.FREE_DRAW) {
        canvasRef.current.isDrawingMode = true;
        if (!config.hasColorPicker && config.color) {
          canvasRef.current.freeDrawingBrush = getPaintBrush(
            config.color,
            visibleHeight,
            canvasRef.current
          );
          emitBrushChange({ color: config.color });
        }
        canvasRef.current.freeDrawingCursor = "crosshair";
        setShowCursor(false);
      }
      if (config.type === ControlButtonType.TEXT) {
        canvasRef.current.isDrawingMode = false;
        canvasRef.current.defaultCursor = "text";
        canvasRef.current.hoverCursor = "text";
        setShowCursor(false);
      }
      if (config.type === ControlButtonType.DELETE) {
        canvasRef.current.isDrawingMode = true;
        canvasRef.current.freeDrawingCursor = "none";
        canvasRef.current.freeDrawingBrush = getEraserBrush(
          visibleHeight,
          canvasRef.current
        );
        setCursorRadius((visibleHeight * ERASER_BRUSH_HEIGHT_MULTIPLIER) / 2);
        setShowCursor(true);
      }
    }
  };

  const selectedButtonOutlineWidth =
    TOOLBAR_BUTTON_OUTLINE_MULTIPLIER * buttonHeight;
  const buttonStyle = {
    height: buttonHeight,
    width: buttonHeight,
    borderRadius: buttonHeight,
    fontSize: (38 / 60) * buttonHeight,
    outlineWidth: isSelected ? selectedButtonOutlineWidth : 0,
  };
  const iconStyle = {
    height: (2 / 3) * buttonHeight,
    width: (3 / 3) * buttonHeight,
  };

  if (config.type === ControlButtonType.DIVIDER) {
    return (
      <div
        className={styles.dividerContainer}
        style={{ height: buttonHeight * 0.25, width: buttonHeight }}
      >
        <div className={styles.divider} />
      </div>
    );
  }

  if (config.type === ControlButtonType.FREE_DRAW) {
    if (config.hasColorPicker) {
      return (
        <MultiColorButton
          id={id}
          isSelected={isSelected}
          onClick={onClick}
          updateCanvasBrushColor={updateCanvasBrushColor}
          buttonStyle={buttonStyle}
          containerRef={containerRef}
        />
      );
    }
    return (
      <button
        className={clsx(styles.button, { [styles.isSelected]: isSelected })}
        style={{ ...buttonStyle, backgroundColor: config.color }}
        onClick={onClick}
      />
    );
  }

  if (config.type === ControlButtonType.TEXT) {
    return (
      <TextButton
        isSelected={isSelected}
        buttonStyle={buttonStyle}
        textSizeRef={textSizeRef}
        onClick={onClick}
      />
    );
  }

  if (config.type === ControlButtonType.DELETE) {
    return (
      <button
        id={"eraserButton"}
        className={clsx(styles.button, { [styles.isSelected]: isSelected })}
        style={{ ...buttonStyle, backgroundColor: COLORS.GRAY }}
        onClick={onClick}
      >
        <img src={deleteIcon} style={iconStyle} />
      </button>
    );
  }

  return null;
};

export default ControlButton;

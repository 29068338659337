import { useRemoteParticipants } from "@livekit/components-react";
import { ROOM_LIVEKIT_PARTICIPANT_ID } from "./WebRTCContext";

export const usePeersParticipants = () => {
  const remoteParticipants = useRemoteParticipants();
  return remoteParticipants.filter(
    (participant) => participant.identity !== ROOM_LIVEKIT_PARTICIPANT_ID
  );
};

export const useIsConnectedToSomePeer = () => {
  const peers = usePeersParticipants();
  return peers.length > 0;
};

import React, { useEffect } from "react";
import {
  GatewayConnectionProvider,
  useJoinRoomError,
} from "./components/ConnectionsContext/GatewayConnectionContext";
import { RoomConnectionProvider } from "./components/ConnectionsContext/RoomConnectionContext";
import { WebRTCContextProviderWrapper } from "./components/ConnectionsContext/WebRTCContext";
import { useGetSpaceDataQuery } from "generated/graphql";
import { selectMeetingID } from "redux/spaceNavigationRedux";
import { useDispatch, useSelector } from "react-redux";
import { useIsTherapist } from "./hooks/useIsTherapist";
import {
  selectEncodedClientToken,
  selectSignedOut,
  setCurrentRoomId,
  setProviderId,
  setProviderIsInternal,
  setUserJoinTimestamp,
} from "redux/userRedux";
import { logUnexpectedError } from "utils/errorUtils";
import {
  setAudioProviderOnly,
  setEnableResourceNameViewer,
  setIsVideoBlurred,
  setVideoConferencing,
} from "redux/settingsRedux";
import { isMobileDevice } from "utils/deviceUtils";
import { setCurrentClient } from "redux/clientManagementRedux";
import ConnectionError from "./subpages/ConnectionError/ConnectionError";
import JoinedSpace from "./JoinedSpace";
import { useRemoteSettings } from "./components/ControlBar/useRemoteSettings";
import { useSyncState } from "./hooks/useSyncState";
import { useConnectionAnalytics } from "./hooks/connection/useConnectionAnalytics";
import { useIntercom } from "./hooks/useIntercom";

const Space = () => {
  const meetingID = useSelector(selectMeetingID);
  const isTherapist = useIsTherapist();
  const encodedClientToken = useSelector(selectEncodedClientToken);

  const dispatch = useDispatch();

  const { data: spaceData, error: spaceDataError } = useGetSpaceDataQuery({
    variables: {
      meetingID: meetingID || "",
      isProvider: isTherapist,
    },
    // Avoiding query in first render where encodedClientToken is not set yet
    // for clients
    skip: !meetingID || (!isTherapist && !encodedClientToken),
  });

  useEffect(() => {
    if (spaceData) {
      const roomId = spaceData.meeting_by_pk?.provider?.current_room?.id;
      dispatch(setCurrentRoomId(roomId));
      const provider = spaceData?.meeting_by_pk?.provider;
      const providerId = provider?.id;
      dispatch(setProviderId(providerId));
      const isInternal = provider ? provider.is_internal : null;
      dispatch(setProviderIsInternal(isInternal));
      const joinTimestamp = provider?.join_timestamp;
      dispatch(setUserJoinTimestamp(joinTimestamp));
      if (isTherapist) {
        const audioProviderOnly =
          provider?.provider_settings?.audio_provider_only;
        dispatch(setAudioProviderOnly(audioProviderOnly));
        const videoConferencing =
          provider?.provider_settings?.video_conferencing;
        dispatch(setVideoConferencing(videoConferencing));
        const resourceNameViewer =
          provider?.provider_settings?.resource_name_viewer;
        dispatch(setEnableResourceNameViewer(resourceNameViewer));
        if (!isMobileDevice()) {
          const videoBlur = provider?.provider_settings?.video_blur;
          dispatch(setIsVideoBlurred(videoBlur));
        }
      }
      const currentClient = provider?.current_client;
      if (currentClient) {
        dispatch(
          setCurrentClient({
            canonical_id: currentClient.canonical_id,
            name: currentClient.name,
          })
        );
      }
    }
  }, [spaceData]);

  useIntercom(true, spaceData?.meeting_by_pk?.provider?.first_name);

  useEffect(() => {
    if (spaceDataError) {
      logUnexpectedError(spaceDataError);
    }
  }, [spaceDataError]);

  useRemoteSettings();
  useSyncState();
  useConnectionAnalytics(isTherapist);

  const joinRoomError = useJoinRoomError();

  const signedOut = useSelector(selectSignedOut);
  // This state is only relevant for EHR systems sessions, because for
  // Teleo-initiated sessions, we always redirect users at sign-out right away.
  if (signedOut) {
    return (
      <ConnectionError
        errorMessage="You have been signed out."
        loading={false}
        showInviteLink={false}
      />
    );
  }

  if (!meetingID) {
    return (
      <ConnectionError
        errorMessage={"Invalid url"}
        loading={false}
        showInviteLink={false}
      />
    );
  }

  const hasSessionEnded =
    joinRoomError === "Session ended." ||
    (!!joinRoomError &&
      (joinRoomError.includes("Disconnected.") ||
        joinRoomError.includes("Session timed out") ||
        joinRoomError.includes("Signing out")));

  return (
    <>
      {!hasSessionEnded && <JoinedSpace />}
      <ConnectionError
        errorMessage={joinRoomError ?? ""}
        // TODO: properly define loading appearance
        loading={false}
        showInviteLink={false}
        hasSessionEnded={hasSessionEnded}
        isGlobalError
      />
    </>
  );
};

const SpaceContextWrapper = () => {
  return (
    <GatewayConnectionProvider>
      <RoomConnectionProvider>
        <WebRTCContextProviderWrapper>
          <Space />
        </WebRTCContextProviderWrapper>
      </RoomConnectionProvider>
    </GatewayConnectionProvider>
  );
};

export default SpaceContextWrapper;

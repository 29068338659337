import React, { useEffect, useMemo } from "react";

import { ReactComponent as XIcon } from "assets/icons/x.svg";
import { ReactComponent as ExpandIcon } from "assets/icons/expand_more.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentRoomItemId,
  selectMeetingID,
  selectThumbnailSrcs,
  setIsFullScreenVideo,
  selectIsLaunchActivitySideBarOpen,
  setIsLaunchActivitySideBarOpen,
} from "redux/spaceNavigationRedux";
import ResourceThumbnail from "pages/Space/subpages/SpaceRoom/components/ResourceEntry/ResourceThumbnail/ResourceThumbnail";
import { ResourceType } from "pages/Space/subpages/SpaceRoom/utils/drawingUtils";
import ResourceCategoryIcon from "pages/Space/subpages/SpaceRoom/components/ResourceEntry/ResourceCategoryIcon/ResourceCategoryIcon";
import clsx from "clsx";

import styles from "./LaunchActivitySideBar.module.css";
import { RoomItemFragment, useGetControlBarDataQuery } from "generated/graphql";
import { useLaunchActivity } from "pages/Space/subpages/SpaceRoom/hooks/useLaunchActivity";
import { POSTER_RESOURCE_ID } from "utils/resourceUtils";
import { selectUserRole, UserRole } from "redux/userRedux";
import HelpIndicator from "components/HelpIndicator/HelpIndicator";
import { scrubResourceData, useTrackEvent } from "utils/metricsUtils";
import { useGetRoomQuerySharedHook } from "pages/Space/subpages/SpaceRoom/hooks/useGetRoomQuerySharedHook";
import Tooltip from "components/Tooltip/Tooltip";
import { FloatingDelayGroup } from "@floating-ui/react";

type LaunchActivitySideBarProps = {};

const LaunchActivitySideBar = ({}: LaunchActivitySideBarProps) => {
  const currentRoomItemId = useSelector(selectCurrentRoomItemId);
  const isOpen = useSelector(selectIsLaunchActivitySideBarOpen);
  const thumbnailSrcs = useSelector(selectThumbnailSrcs);

  const { trackEvent } = useTrackEvent();

  const { data: getRoomData } = useGetRoomQuerySharedHook();

  const roomItems = getRoomData?.meeting[0].provider?.current_room?.room_items;

  const roomItemsWithDuplicatedCount = useMemo(() => {
    if (!roomItems) {
      return [];
    }

    const filteredRoomItems = roomItems.filter(
      (item) => item.resource.id !== POSTER_RESOURCE_ID
    );

    const sortedRoomItems = [...filteredRoomItems].sort(
      (a, b) => (a.resource.order ?? 0) - (b.resource.order ?? 0)
    );

    const result: { item: RoomItemFragment; number?: number }[] = [];
    const resourcesCount = new Map<string, number>();

    // Add a number to the end of the resource name if there are duplicates
    for (const item of sortedRoomItems) {
      const resourceId = item.resource.id;
      const existingResourceCount = resourcesCount.get(resourceId) ?? 0;

      resourcesCount.set(resourceId, existingResourceCount + 1);
      result.push({ item, number: existingResourceCount + 1 });
    }

    for (const [resourceId, count] of resourcesCount.entries()) {
      if (count === 1) {
        const item = result.find(
          (item) => item.item.resource.id === resourceId
        );
        if (item) {
          item.number = undefined;
        }
      }
    }
    return result;
  }, [roomItems]);

  const dispatch = useDispatch();

  const launchActivity = useLaunchActivity();

  const handleSlideButtonClick = () => {
    dispatch(setIsLaunchActivitySideBarOpen(!isOpen));
    trackEvent(`Activity Sidebar - ${isOpen ? "Closed" : "Opened"}`, {
      ["source"]: "slide button",
    });
  };

  useEffect(() => {
    if (isOpen) {
      trackEvent("Activity Sidebar - Opened", {
        ["source"]: "first open",
      });
    }
  }, []);

  const handleCloseButtonClick = () => {
    dispatch(setIsLaunchActivitySideBarOpen(false));
    trackEvent("Activity Sidebar - Closed", {
      ["source"]: "x button",
    });
  };

  const launchActivityHandler = (item: RoomItemFragment) => {
    const launchingResource = scrubResourceData(item.resource);

    const getCurrentResource = () => {
      const currentRoomItem = roomItems?.find(
        (roomItem) => roomItem.id === currentRoomItemId
      );
      return currentRoomItem
        ? scrubResourceData(currentRoomItem.resource)
        : { id: "Room", name: "Room" };
    };

    const currentResource = getCurrentResource();

    trackEvent("Activity Sidebar - Activity Launched", {
      ["resource id"]: launchingResource.id,
      ["resource name"]: launchingResource.name,
      ["current opened resource id"]: currentResource.id,
      ["current opened resource name"]: currentResource.name,
    });

    launchActivity(item, true);
    dispatch(setIsFullScreenVideo(false));
    trackEvent("Video chat - talk mode closed", {
      ["source"]: "Launch activity from sidebar",
    });
  };

  const meetingID = useSelector(selectMeetingID);
  const userRole = useSelector(selectUserRole);

  const { data: controlBarData } = useGetControlBarDataQuery({
    variables: {
      meetingID: meetingID ?? "",
    },
    skip: !meetingID || userRole !== UserRole.THERAPIST,
  });

  const roomNameFromDb =
    controlBarData?.meeting_by_pk?.provider?.current_room?.name;

  const roomNameToDisplay = roomNameFromDb || "Unnamed Room";

  return (
    <div
      className={clsx(styles.launchActivityContainer, {
        [styles.isOpen]: isOpen,
      })}
    >
      <div className={styles.shrinkableContainer}>
        <div className={styles.nonShrinkableContainer}>
          <div className={styles.headerRow}>
            <div className={styles.header}>
              {roomNameToDisplay}
              <HelpIndicator text="Only you can see this panel." />
            </div>

            <XIcon className={styles.xIcon} onClick={handleCloseButtonClick} />
          </div>
          <div className={styles.body}>
            <FloatingDelayGroup delay={{ open: 500, close: 500 }}>
              {roomItemsWithDuplicatedCount.map(({ item, number }) => (
                <div
                  key={item.id}
                  className={styles.card}
                  onClick={() => launchActivityHandler(item)}
                >
                  <div className={styles.thumbnailContainer}>
                    <ResourceThumbnail
                      resourceType={
                        item.resource.url
                          ? ResourceType.WEBSITE
                          : ResourceType.FILE
                      }
                      thumbnailSrc={
                        item.resource.thumbnail_file_key
                          ? thumbnailSrcs[item.resource.thumbnail_file_key]
                          : undefined
                      }
                    />
                  </div>
                  <div className={styles.cardBody}>
                    <ResourceCategoryIcon
                      category={
                        item.resource.categories ||
                        (!!item.resource?.owner_id ? "private" : null)
                      }
                      containerClassName={styles.cardIconContainer}
                      iconClassName={styles.cardIcon}
                    />
                    <Tooltip
                      text={`${item.resource.name}${
                        number ? ` #${number}` : ""
                      }`}
                    >
                      <div
                        className={clsx(styles.cardText, {
                          [styles.breakAll]: !!item.resource?.owner_id,
                        })}
                      >
                        {item.resource.name}
                        {number ? ` #${number}` : ""}
                      </div>
                    </Tooltip>
                  </div>
                </div>
              ))}
            </FloatingDelayGroup>
          </div>
        </div>
      </div>
      <button className={styles.slideButton} onClick={handleSlideButtonClick}>
        <div className={styles.visibleArea}>
          <ExpandIcon className={styles.expandIcon} />
        </div>
      </button>
    </div>
  );
};

export default LaunchActivitySideBar;

import {
  setCurrentAlbumPage,
  setImgKeysToURL,
  setAlbumPages,
  setViewingSnapshot,
} from "redux/clientAlbumRedux";
import { useDispatch } from "react-redux";
import { useTeleoEvent } from "pages/Space/components/ConnectionsContext/teleoPeerEventUtils";

export const useRemoteAlbum = () => {
  const dispatch = useDispatch();

  const sendInitializeAlbum = useTeleoEvent("albumInitialize", (payload) => {
    dispatch(setAlbumPages(payload.pages));
    dispatch(setCurrentAlbumPage(payload.currentPage));
    dispatch(setImgKeysToURL(payload.imgKeysToURL));
    dispatch(setViewingSnapshot(payload.viewingSnapshot));
  });

  const sendMoveToPage = useTeleoEvent("albumMoveToPage", (payload) => {
    dispatch(setCurrentAlbumPage(payload.page));
  });

  const sendSetViewingSnapshot = useTeleoEvent(
    "albumSetViewingSnapshot",
    (payload) => {
      dispatch(setViewingSnapshot(payload.snapshot));
    }
  );

  return { sendInitializeAlbum, sendMoveToPage, sendSetViewingSnapshot };
};
